article{
    line-height: 2;
    ul{
        list-style: none;
    }
    img{
        max-width: 100%;
        width: auto;
        height: auto;
        &.aligncenter{
            margin: 0 auto;
            display: block;
        }
        &.alignright{
            margin: 0 0 0 auto;
            display: block;
        }
    }
    //アキ調整
    p,.commonList, ol, .imgLine2, .imgLine3, .imgLine4, .twoCol, .commonTable, .colorBox, .borderBox, .ytWrap, .common_dl_wrap, .link_list, .info_box{
        @if $type == sp{
            margin-top: 30px;
        }
        @if $type == pc{
            margin-top: 60px;
        }
    }
    h1,h2,h3,h4{
        line-height: 1.3;
    }
    a{
        color: $c_base;
        &:hover{
            text-decoration: none;
        }
    }
    p.narrow{
        @if $type == sp{
            margin-top: 15px;
        }
        @if $type == pc{
            margin-top: 30px;
        }
    }
    .ff_en{
        font-family: $ff_en;
    }
    .common_btn{
        color: #fff;
        text-decoration: none;
        letter-spacing: 0.1em;
        display: inline-block;
        position: relative;
        @if $type == sp{
            width: 100%;
        }
        @if $type == pc{
            &:hover{
                span{
                    //background-color: #fff;
                    //color: $c_base;
                    &:before{
                        background: url(../images/common/arrow_blue.png) no-repeat #fff center;
                    }
                }
            }
            &:before{
                content: "";
                display: inline-block;
                background: url(../images/common/botton_shadow.png) no-repeat;
                width: 100%;
                height: 65px;
                position: absolute;
                right: -12px;
                bottom: -12px;
            }
        }

        span{
            display: block;
            position: relative;
            color: #fff;
            background-color: $c_base;
            border: 1px solid $c_base;
            width: 100%;
            height: 100%;
            @if $type == sp{
                font-size: 13px;
                margin-top: 15px;
                padding: 15px 60px;
                z-index: 2;
            }
            @if $type == pc{
                font-size: 18px;
                margin-top: 30px;
                padding: 25px 100px;
                z-index: 2;
                transition: .3s;
            }
            &:before{
                content: "";
                display: inline-block;
                border: 1px solid #fff;
                border-radius: 50%;
                position: absolute;
                background: url(../images/common/arrow_w.png) no-repeat center;
                @if $type == sp{
                    top: calc(50% - 15px);
                    right: 15px;
                    width: 30px;
                    height: 30px;
                    background-size: 8px;
                }
                @if $type == pc{
                    top: calc(50% - 25px);
                    right: 25px;
                    width: 50px;
                    height: 50px;
                    transition: .3s;
                }
            }
        }
    }
    .more_btn{
        text-decoration: none;
        color: #000;
        display: inline-block;
        position: relative;
        font-weight: bold;
        font-family: $ff_en;
        @if $type == sp{
            margin-top: 30px;
            font-size: 14px;
            padding-left: 65px;
        }
        @if $type == pc{
            margin-top: 60px;
            font-size: 19px;
            padding-left: 90px;
            &:hover{
                &:before{
                    background: url(../images/common/arrow_w.png) no-repeat center $c_base;
                    border: 1px solid $c_base;
                }
            }
        }
        &:before{
            content: "";
            display: inline-block;
            border: 1px solid #000;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../images/common/arrow.png) no-repeat center;
            @if $type == sp{
                width: 45px;
                height: 45px;
                background-size: 8px;
            }
            @if $type == pc{
                width: 70px;
                height: 70px;
                transition: .3s;
            }
        }
        span{
            display: inline-block;
            background: url(../images/common/more_line.png) no-repeat left bottom;
            line-height: 1;
            @if $type == sp{
                padding: 5px 0 20px;
            }
            @if $type == pc{
                padding: 15px 0 30px;
            }
        }
    }
    h2.title,.post h2{
        color: $c_base;
        font-weight: bold;
        text-align: center;
        @if $type == sp{
            font-size: 22px;
            padding-top: 20px;
            margin-top: 50px;
            border-top: 2px solid $c_base;
            + .h2_en_title{
                text-align: center;
                color: $c_base;
                margin-top: 0px;
                font-size: 16px;
            }
        }
        @if $type == pc{
            font-size: 26px;
            padding-top: 40px;
            margin-top: 100px;
            border-top: 3px solid $c_base;
            + .h2_en_title{
                text-align: center;
                color: $c_base;
                margin-top: 0px;
                font-size: 18px;
            }
        }
    }
    h2.title2{
        font-weight: bold;
        position: relative;
        color: $c_base;
        @if $type == sp{
            font-size: 22px;
            margin-top: 40px;
            padding-right: 50px;
        }
        @if $type == pc{
            font-size: 26px;
            margin-top: 80px;
            padding-right: 100px;
        }
        &:after{
            content: "";
            display: block;
            background-color: $c_base;
            width: 100%;
            height: 2px;
            position: absolute;
            left: 0;
            top: calc(50% - 1px);
        }
        span{
            display: inline-block;
            background-color: #fff;
            position: relative;
            z-index: 2;
            @if $type == sp{
                padding-right: 15px;
            }
            @if $type == pc{
                padding-right: 30px;
            }
        }
    }
    h3.title,.post h3{
        font-weight: bold;
        position: relative;
        color: $c_text;
        @if $type == sp{
            font-size: 18px;
            margin-top: 40px;
            padding-left: 35px;
            + p{
                margin-top: 15px;
            }
        }
        @if $type == pc{
            font-size: 22px;
            margin-top: 80px;
            padding-left: 40px;
            + p{
                margin-top: 30px;
            }
        }
        &:before{
            content: "";
            display: inline-block;
            border: 2px solid $c_base;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            @if $type == sp{
                width: 20px;
                height: 20px;
            }
            @if $type == pc{
                width: 26px;
                height: 26px;
            }
        }
    }
    section + section{
        @if $type == sp{
            margin-top: 70px;
        }
        @if $type == pc{
            margin-top: 150px;
        }
    }
}//article

.kome{
    display: block;
    padding-left: 1em;
    text-indent: -1em;
    margin-top: 10px;
    @if $type == sp{
        font-size: 12px;
    }
    @if $type == pc{
        font-size: 14px;
    }
    &:before{
        content: "※";
    }
    + .kome{
        margin-top: 0;
    }
}

a.icon{
    display: inline-block;
    position: relative;
    padding-right: 20px;

    &:after{
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: 50% 0;
        position: absolute;
        right: -5px;
        top: .1em;
    }

    &[href $= pdf]{
        &:after{
            background-image: url(../images/common/icon_pdf.png);
        }
    }

    &[href $= doc], &[href $= docx]{
        &:after{
            background-image: url(../images/common/icon_doc.png);
        }
    }

    &[href $= xls], &[href $= xlsx]{
        &:after{
            background-image: url(../images/common/icon_xls.png);
        }
    }
}

.ico_blank{
    display: inline-block;
    width: 11px;
    height: 11px;
    background:url("../images/common/icon_blank.svg") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 5px;
    position: relative;
    top: -4px;
}


.imgLine2{
    @if $type == sp{
    }
    @if $type == pc{
        display: flex;
        justify-content: center;
    }

    li{
        text-align: center;
        @if $type == sp{
            margin-top: 20px;
        }
        @if $type == pc{
            width: calc(50% - 30px);
            &:nth-of-type(odd){
                margin-right: 60px;
            }
        }

        img{
            //width: 100%;
            max-width: 100%;
        }
    }

    &.small li{
        @if $type == pc{
            width: calc(33% - 40px);
        }
    }

    .cap{
        display: block;
        text-align: left;
        font-size: 12px;
        margin-top: 5px;
    }
}

.imgLine3{
    @if $type == sp{
    }
    @if $type == pc{
        display: flex;
        justify-content: center;
    }

    li{
        text-align: center;
        @if $type == sp{
            margin-top: 20px;
        }
        @if $type == pc{
            width: calc(33% - 40px);
            &:not(:nth-of-type(3n)){
                margin-right: 60px;
            }
        }

        img{
            max-width: 100%;
        }
    }
    .cap{
        display: block;
        text-align: left;
        font-size: 12px;
        margin-top: 5px;
    }
}

.imgLine4{
    @if $type == sp{
        display: flex;
        flex-wrap: wrap
    }
    @if $type == pc{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    li{
        @if $type == sp{
            width: calc(50% - 10px);
            margin-right: 20px;

            &:nth-of-type(2n){
                margin-right: 0;
            }
            &:nth-child(n + 3){
                margin-top: 20px;
            }

        }
        @if $type == pc{
            width: calc(25% - 36px);
            margin-right: 48px;

            &:nth-of-type(4n){
                margin-right: 0;
            }
            &:nth-of-type(n + 5){
                margin-top: 48px;
            }
            a{
                display: block;
                transition: .4s;
                &:hover{
                    opacity: .7;
                }
            }
        }
        img{
            width: 100%;
        }
    }
    .cap{
        display: block;
        text-align: left;
        font-size: 12px;
        margin-top: 5px;
    }
}


.imgInP{
    @if $type == sp{
    }
    @if $type == pc{
        overflow: hidden;
    }

    .img{
        @if $type == sp{
            display: block;
            margin: 0 auto;
            padding-bottom: 10px;
        }
        @if $type == pc{
            max-width: 300px;

            img{
                width: 100%;
                height: auto;
            }
        }

        .cap{
            display: block;
            text-align: left;
            font-size: 13px;
            margin-top: 5px;
        }
    }

    .img.left{
        @if $type == sp{
        }
        @if $type == pc {
            float: left;
            margin-right: 20px;
        }
    }

    .img.right{
        @if $type == sp{
        }
        @if $type == pc{
            float: right;
            margin-left: 20px;
        }
    }

    &.sp_img_btm{
        @if $type == sp{
            display: flex;
            flex-wrap: wrap;

            .img{
                display: block;
                order: 2;
                margin: 10px auto 0;
                padding-bottom: 0;
            }

            .text{
                flex-basis: 100%;
                order: 1;
            }
        }
    }
}

.twoCol{

    @if $type == sp{
        &.wide{
            margin-top: 60px;
        }
        &.logo{
            .img{
                text-align: center;
                img{
                    width: 180px;
                }
            }
        }
        .txt{

            *:nth-child(1){
                margin-top: 0;
            }

            & + .img{
                margin-top: 30px;
            }
        }

        .img{
            width: 100%;
            text-align: center;
            &.round{
                overflow: hidden;
                border-radius: 15px;
            }

            & + .txt{
                margin-top: 20px;
            }

            img{
                width: 100%;
                height: auto;
            }
            .cap{
                display: block;
                margin-top: 5px;
                font-size: 12px;
                text-align: left;
            }
            .modal_image{
                text-decoration: none;
                span{
                    display: block;
                    margin-top: 5px;
                    text-align: right;
                    font-size: 10px;
                }
            }
            .modal_slide:not(:first-of-type){
                display: none;
            }
        }
        &.sp_reverse{
            display: flex;
            flex-direction: column-reverse;
            > div:last-of-type{
                margin-bottom: 20px;
            }
            .img{
                margin-top: 0;
            }
        }
        .twoCol_title{
            font-weight: bold;
            font-size: 22px;
            position: relative;
            padding-top: 26px;
            padding-left: 26px;
            padding-right: 26px;
            &:before{
                content: "";
                display: inline-block;
                background: url(../images/common/h2_kazari.png) no-repeat;
                width: 26px;
                height: 26px;
                position: absolute;
                top: 0;
                left: 0px;
            }
            + p{
                margin-top: 15px;
                padding-left: 26px;
                padding-right: 26px;
            }
        }
        .twoCol_title_2{
            font-weight: bold;
            font-size: 22px;
            position: relative;
            padding-left: 55px;
            .num{
                font-family: $ff_oswald;
                font-size: 36px;
                color: #868686;
                position: absolute;
                top: 0px;
                left: 0;
                font-weight: normal;
                line-height: 1;    
                span{
                    color: $c_base;
                }
            }
            + p{
                margin-top: 15px;
            }
        }
    }
    @if $type == pc{
        display: flex;
        &.items_center{
            align-items: center;
        }
        &.wide{
            margin-top: 90px;
            .img{
                width: 57%;
            }
            .txt{
                width: 43%;
            }
        }
        &.narrow{
            .img{
                width: 38%;
            }
            .txt{
                width: 62%;
            }
        }
        &.logo{
            .img{
                width: 180px;
            }
            .txt{
                width: calc(100% - 180px);
            }
        }
        > div{
            width: 50%;
            &:first-of-type{
                padding-right: 30px;
            }
            &:last-of-type{
                padding-left: 30px;
            }
        }
        .txt{
            flex: 1;

            *:nth-child(1){
                margin-top: 0;
            }
        }

        .img{
            &.round{
                overflow: hidden;
                border-radius: 30px;
            }

            img{
                width: 100%;
                height: auto;
            }
            .cap{
                display: block;
                margin-top: 5px;
                font-size: 13px;
                text-align: left;
            }
            .modal_image{
                text-decoration: none;
                display: block;
                transition: .4s;
                &:hover{
                    opacity: .7;
                    color: $c_text;
                }
                span{
                    display: block;
                    margin-top: 5px;
                    text-align: right;
                    font-size: 13px;
                }
            }
            .modal_slide:not(:first-of-type){
                display: none;
            }
        }
        .twoCol_title{
            font-weight: bold;
            font-size: 28px;
            position: relative;
            padding-top: 28px;
            &:before{
                content: "";
                display: inline-block;
                background: url(../images/common/h2_kazari.png) no-repeat;
                width: 26px;
                height: 26px;
                position: absolute;
                top: 0;
                left: -28px;
            }
            + p{
                margin-top: 20px;
            }
        }

        .twoCol_title_2{
            font-weight: bold;
            font-size: 28px;
            position: relative;
            padding-left: 75px;
            .num{
                font-family: $ff_oswald;
                font-size: 50px;
                color: #868686;
                position: absolute;
                top: -6px;
                left: 0;
                font-weight: normal;
                line-height: 1;
                span{
                    color: $c_base;
                }
            }
            + p{
                margin-top: 20px;
            }
        }
    }
}

//リスト系

ul.commonList{

    li{
        padding-left: 15px;
        position: relative;
        box-sizing: border-box;

        &:not(:first-of-type){
            margin-top: 10px;
        }

        &:before{
            content: "";
            display: inline-block;
            width: 6px;
            height: 6px;
            margin: auto;
            content: '';
            border-radius: 50%;
            background-color: $c_base;
            position: absolute;
            left: 0;
            top: 14px;
        }
    }
}

ul.col2List{
    @if $type == sp{
    }
    @if $type == pc{
        display: flex;
        flex-wrap:wrap;
    }

    li{
        @if $type == sp{
        }
        @if $type == pc{
            width: 50%;
            flex-basis:50%;

            &:not(:first-of-type){
                margin-top: 0px;
            }

            &:nth-of-type(n + 3){
                margin-top: 10px;
            }
        }
    }
}//col2List

ol{
    padding-left: 18px;
    @if $type == sp{
    }
    @if $type == pc{
    }

    li{

        & + li{
            margin-top: 10px;
        }
    }
}

//テーブル

.commonTable{
    width: 100%;
    border-collapse: collapse;
    border-spacing: inherit;
    background-color: #FAFAFA;

    @if $type == sp{
    }
    @if $type == pc{
    }

    td.wFix,
    th.wFix{
        @if $type == sp{
            width: 120px;
        }
        @if $type == pc{
            width: 150px;
        }
    }

    th{
        color: $c_text;
        text-align: left;
        font-weight: normal;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        background-color: #F5F6F6;

        &.gray{
            background-color: #F5F6F6;
        }

        @if $type == sp{
            font-size: 13px;
            padding: 12px 15px;
            &:not(:first-of-type){
                border-left: 1px solid $c_gray;
            }
        }
        @if $type == pc{
            font-size: 13px;
            padding: 15px 20px;
        }

        &.null{
            border-top: 1px solid #fff;
            border-left: 1px solid #fff;
            background-color: #fff;
        }
    }

    td{
        padding: 12px;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        word-break: break-all;

        &.gray{
            background-color: #F5F6F6;
        }

        *:last-child{
            margin-bottom: 0;
        }

        *:first-child{
            margin-top: 0;
        }

        @if $type == sp{
            font-size: 13px;
            padding: 12px 15px;
            &:not(:first-of-type){
                border-left: 1px solid #fff;
            }
        }
        @if $type == pc{
            font-size: 13px;
            padding: 15px 20px;
        }

        &.null{
            border-top: 1px solid #fff;
            border-left: 1px solid #fff;
        }
    }
}

.scrollTable{
    overflow: auto;

    table{
        @if $type == sp{
            white-space: nowrap;
        }
    }
}

.scrolltext{
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 20px 0 -10px;
}

.colorBox{
    background-color: #f5f5f5;

    *:nth-child(1){
        margin-top: 0;
    }

    @if $type == sp{
        padding: 20px 20px;
    }
    @if $type == pc{
        padding: 60px 80px;
    }

    .title{
        display: block;
        @if $type == sp{
            font-size: 16px;
            margin-bottom: 5px;
        }
        @if $type == pc{
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
}

.borderBox{
    border: 1px solid $c_base;
    @if $type == sp{
        padding: 20px;
        + .borderBox{
            margin-top: 15px;
        }
    }
    @if $type == pc{
        padding: 20px;
        + .borderBox{
            margin-top: 30px;
        }
        &.wide{
            padding: 45px 70px;
        }
    }
    *:nth-child(1){
        margin-top: 0;
    }
    .borderBoxTitle{
      font-weight: bold;
        @if $type == sp{
            font-size: 16px;
        }
        @if $type == pc{
            font-size: 18px;
        }
        + p{
            margin-top: 10px;
        }
    }
}
.col2_wrap{
    @if $type == sp{
    }
    @if $type == pc{
        display: flex;
        flex-wrap: wrap;
    }

    .left{
        @if $type == sp{
        }
        @if $type == pc{
            width: calc(50% - 15px);
            margin-right: 30px;
        }
    }

    .right{
        @if $type == sp{
        }
        @if $type == pc{
            width: calc(50% - 15px);
        }
    }
}

.border-box{
    border: 1px solid #ccc;
    padding: 20px;
}

.common_dl_wrap{
    dl{
        &:not(:first-of-type){
            border-top: 1px solid $c_gray;
        }
        @if $type == sp{
            padding: 15px 0;
        }
        @if $type == pc{
            display: flex;
            padding: 15px 0;
        }
        dt{
            font-weight: bold;
            @if $type == sp{
                margin-bottom: 5px;
            }
            @if $type == pc{
                width: 125px;
                padding: 0px 30px 0px 0;
            }
        }
        dd{
            @if $type == sp{
            }
            @if $type == pc{
                width: calc(100% - 125px);
                padding: 0px 0 0px 0px;
            }
        }
    }
    &.small{
        margin-top: 10px;
        dl{
            &:not(:first-of-type){
                border-top: none;
            }
            @if $type == sp{
                padding: 10px 0;
            }
            @if $type == pc{
                display: flex;
                padding: 10px 0;
            }
            dt{
                @if $type == sp{
                    margin-bottom: 5px;
                }
                @if $type == pc{
                    width: 100px;
                    padding: 0px 30px 0px 0;
                }
            }
            dd{
                @if $type == sp{
                }
                @if $type == pc{
                    width: calc(100% - 100px);
                    padding: 0px 0 0px 0px;
                }
            }
        }
    }
    &.big{
        border-top: 1px solid $c_gray;
        border-bottom: 1px solid $c_gray;
        @if $type == sp{
            dl{
                dt{
                    font-size: 18px;
                    margin-bottom: 10px;
                }
            }
        }
        @if $type == pc{
            dl{
                dt{
                    font-size: 22px;
                    width: 240px;
                    padding-left: 40px;
                    padding-top: 30px;
                    padding-bottom: 30px;
                    line-height: 1;
                }
                dd{
                    width: calc(100% - 240px);
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
            }
        }
        &.type2{
            dl{
                dt{
                    @if $type == sp{
                        font-size: 13px;
                    }
                    @if $type == pc{
                        font-size: 15px;
                    }
                }
            }
        }
    }
}


.ytWrap{
    @if $type == sp{
    }
    @if $type == pc{
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;
    }
    .ytWrap_inner{
        position: relative;
        padding-bottom: 56.25%;
    }

    iframe{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}



/* common class */
strong{font-weight: bold!important}


.taC{text-align: center!important;}
.taL{text-align: left!important;}
.taR{text-align: right!important;}
.Center{margin: 0 auto!important;}
.ovh{overflow: hidden!important;}
.mw100{max-width: 100%!important;
    width: auto!important;}
.w100p{width: 100%!important;}
.inline{display: inline!important;}

.mB0{margin-bottom: 0!important;}
.mB5{margin-bottom: 5px!important;}
.mB10{margin-bottom: 10px!important;}
.mB20{margin-bottom: 20px!important;}
.mB30{margin-bottom: 30px!important;}
.mB40{margin-bottom: 40px!important;}
.mB50{margin-bottom: 50px!important;}
.mT0{margin-top: 0!important;}
.mT5{margin-top: 5px!important;}
.mT10{margin-top: 10px!important;}
.mT20{margin-top: 20px!important;}
.mT30{margin-top: 30px!important;}
.mT40{margin-top: 40px!important;}
.mT50{margin-top: 50px!important;}
.mL0{margin-left: 0px!important;}
.mL5{margin-left: 5px!important;}
.mL10{margin-left: 10px!important;}
.mL20{margin-left: 20px!important;}
.mL30{margin-left: 30px!important;}
.mL40{margin-left: 40px!important;}
.mL50{margin-left: 50px!important;}
.mR0{margin-right: 0px!important;}
.mR5{margin-right: 5px!important;}
.mR10{margin-right: 10px!important;}
.mR20{margin-right: 20px!important;}
.mR30{margin-right: 30px!important;}
.mR40{margin-right: 40px!important;}
.mR50{margin-right: 50px!important;}

@if $type == sp{
    .mB0{margin-bottom: 0!important;}
    .mB5_sp{margin-bottom: 5px!important;}
    .mB10_sp{margin-bottom: 10px!important;}
    .mB20_sp{margin-bottom: 20px!important;}
    .mB30_sp{margin-bottom: 30px!important;}
    .mB40_sp{margin-bottom: 40px!important;}
    .mB50_sp{margin-bottom: 50px!important;}
    .mT0{margin-top: 0!important;}
    .mT5_sp{margin-top: 5px!important;}
    .mT10_sp{margin-top: 10px!important;}
    .mT20_sp{margin-top: 20px!important;}
    .mT30_sp{margin-top: 30px!important;}
    .mT40_sp{margin-top: 40px!important;}
    .mT50_sp{margin-top: 50px!important;}
    .mL0_sp{margin-left: 0px!important;}
    .mL5_sp{margin-left: 5px!important;}
    .mL10_sp{margin-left: 10px!important;}
    .mL20_sp{margin-left: 20px!important;}
    .mL30_sp{margin-left: 30px!important;}
    .mL40_sp{margin-left: 40px!important;}
    .mL50_sp{margin-left: 50px!important;}
    .mR0_sp{margin-right: 0px!important;}
    .mR5_sp{margin-right: 5px!important;}
    .mR10_sp{margin-right: 10px!important;}
    .mR20_sp{margin-right: 20px!important;}
    .mR30_sp{margin-right: 30px!important;}
    .mR40_sp{margin-right: 40px!important;}
    .mR50_sp{margin-right: 50px!important;}
    .size12_sp{font-size: 12px !important;}
    .size14_sp{font-size: 14px !important;}
    .size16_sp{font-size: 16px!important;}
    .size18_sp{font-size: 18px!important;}
    .size20_sp{font-size: 20px!important;}
    .w50p_sp{width: 50%!important;}
}
@if $type == pc{
    .mB0_pc{margin-bottom: 0!important;}
    .mB5_pc{margin-bottom: 5px!important;}
    .mB10_pc{margin-bottom: 10px!important;}
    .mB20_pc{margin-bottom: 20px!important;}
    .mB30_pc{margin-bottom: 30px!important;}
    .mB40_pc{margin-bottom: 40px!important;}
    .mB50_pc{margin-bottom: 50px!important;}
    .mT0_pc{margin-top: 0!important;}
    .mT5_pc{margin-top: 5px!important;}
    .mT10_pc{margin-top: 10px!important;}
    .mT20_pc{margin-top: 20px!important;}
    .mT30_pc{margin-top: 30px!important;}
    .mT40_pc{margin-top: 40px!important;}
    .mT50_pc{margin-top: 50px!important;}
    .mL0_pc{margin-left: 0px!important;}
    .mL5_pc{margin-left: 5px!important;}
    .mL10_pc{margin-left: 10px!important;}
    .mL20_pc{margin-left: 20px!important;}
    .mL30_pc{margin-left: 30px!important;}
    .mL40_pc{margin-left: 40px!important;}
    .mL50_pc{margin-left: 50px!important;}
    .mR0_pc{margin-right: 0px!important;}
    .mR5_pc{margin-right: 5px!important;}
    .mR10_pc{margin-right: 10px!important;}
    .mR20_pc{margin-right: 20px!important;}
    .mR30_pc{margin-right: 30px!important;}
    .mR40_pc{margin-right: 40px!important;}
    .mR50_pc{margin-right: 50px!important;}
    .size12{font-size: 12px !important;}
    .size14{font-size: 14px !important;}
    .size16{font-size: 16px!important;}
    .size18{font-size: 18px!important;}
    .size20{font-size: 20px!important;}
    .taC_pc{text-align: center!important;}
    .pointerNone{
        cursor: default;
        text-decoration: none;
        &:hover{
            color: $c_text;
        }
    }
}
.textred{color: red !important;}
.textblue{color: blue !important;}
.bold{font-weight: bold !important;}
.underline{text-decoration: underline!important;}
.bdnone{border: none!important;}

.floatL{float: left;}
.floatR{float: right;}

.preBox{
    background: #eff0f4;
    border: 1px solid $c_base;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8;

    pre{
        overflow: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align-last: auto;
    }
}

.sample{
    @if $type == sp{
    }
    @if $type == pc{
    }
}
