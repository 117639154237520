body{
    @if $type == sp{
        padding-top: 65px;
    }
    @if $type == pc{
        padding-top: 130px;
    }
}
#footer{
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 80px;
    }
}
.common_inner{
    @if $type == sp{
        width: calc(100% - 40px);
        margin: 0 auto;
    }
    @if $type == pc{
        width: 96%;
        max-width: 1120px;
        margin: 0 auto;
        padding: 0 60px;
        &.wide{
            max-width: 1320px;
        }
    }
}
.breadcrumbs{
    display: flex;
    flex-wrap: wrap;
    .common_inner{
        @if $type==sp {
            padding: 5px 0px;
        }
        @if $type==pc {
            padding: 10px 0px;
        }
        > span{
            display: inline-block;
            position: relative;
            padding-right: 15px;
            font-size: 10px;
            color: #000;
            @if $type==sp {
                font-size: 11px;
            }
            @if $type==sp {
                font-size: 14px;
            }
            &:not(:last-of-type)::after{
                content: "/";
                display: inline-block;
                margin-left: 15px;
            }
            a{
                color: $c_base;
            }
        }
    }
}//breadcrumb
#page_header{
    @if $type == sp{
        background: url(../images/second/h1_bg_sp.png) no-repeat;
        background-size: cover;
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;
    }
    @if $type == pc{
        background: url(../images/second/h1_bg.png) no-repeat;
        background-size: cover;
        width: 100%;
        height: 250px;
        display: flex;
        align-items: center;
    }
    .page_header_title{
        font-weight: bold;
        font-family: $ff_en $ff_jp;
        @if $type == sp{
            font-size: 21px;
            padding: 0 20px;
        }
        @if $type == pc{
            font-size: 40px;
            width: 96%;
            max-width: 1260px;
            margin: 0 auto;
            padding: 0 60px 0 0;
        }
    }
}

#contents{
    line-height: 2;
    position: relative;
    @if $type == sp{
        padding: 40px 0 100px;
    }
    @if $type == pc{
        padding: 70px 0 100px;
        background-image: url(../images/second/contens_bg_2.png),url(../images/second/contens_bg_1.png);
        background-position: right 0% top 25%,left 0% top 50%;
        background-repeat: no-repeat;
    }
}

.page_catch{
    font-weight: bold;
    border-bottom: 1px solid #000;
    @if $type == sp{
        padding-bottom: 30px;
        
    }
    @if $type == pc{
        font-size: 18px;
        padding-bottom: 50px;
    }
}

.wp-pagenavi{
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: $ff_en;
    line-height: 1;
    @if $type == sp{
        margin-top: 45px;
    }
    @if $type == pc{
        margin-top: 90px;
    }
    .page,.nextpostslink,.previouspostslink,.extend,.last,.first{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        border: 1px solid $c_base;
        color: $c_base;
        text-decoration: none;
        font-weight: normal;
        @if $type == sp{
            min-width: 30px;
            min-height: 30px;
            margin: 0 3px;
        }
        @if $type == pc{
            min-width: 40px;
            min-height: 40px;
            transition: .4s;
            margin: 0 5px;
            &:hover{
                color: #fff;
                background-color: $c_text;
                border: 1px solid $c_text;
                opacity: 1;
            }
        }
    }
    .extend{
        border: none;
        &:hover{
            background: #fff;
            opacity: 1;
            border: none !important;
            color: $c_text;
        }
    }
    span.current{
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $c_text;
        background-color: $c_text;
        color: #fff;
        font-weight: normal;
        @if $type == sp{
            min-width: 30px;
            min-height: 30px;
            margin: 0 3px;
        }
        @if $type == pc{
            min-width: 40px;
            min-height: 40px;
            margin: 0 5px;
        }
    }
    .previouspostslink{
        position: relative;
        &:hover{
            &:before{
                border-bottom: solid 1px #fff;
                border-left: solid 1px #fff;
            }
        }
    }
    .nextpostslink{
        position: relative;
        &:hover{
            &:before{
                border-top: solid 1px #fff;
                border-right: solid 1px #fff;
            }
        }
    }
    .previouspostslink:before{
        content: '';
        width: 6px;
        height: 6px;
        border: 0px;
        border-bottom: solid 1px $c_base;
        border-left: solid 1px $c_base;
        transform: rotate(45deg);
        position: absolute;
        top: calc(50% - 3px);
        right: 14px;
        @if $type == sp{
            right: 9px;
        }
    }
    .nextpostslink:before{
        content: '';
        width: 6px;
        height: 6px;
        border: 0px;
        border-top: solid 1px $c_base;
        border-right: solid 1px $c_base;
        transform: rotate(45deg);
        position: absolute;
        top: calc(50% - 3px);
        left: 14px;
        @if $type == sp{
            left: 9px;
        }
    }
}//wp-pagenavi

#contents.post{
    @if $type == sp{
        background: url(../images/second/h1_bg_sp.png) no-repeat top center;
        background-size: contain;
    }
    @if $type == pc{
        background: url(../images/second/post_h1_bg.png) no-repeat top center;
        padding-top: 80px;
    }
    .post_h1{
        @if $type == sp{
            font-size: 20px;
            margin-bottom: 30px;
        }
        @if $type == pc{
            font-size: 34px;
            margin-bottom: 70px;
        }
    }
    .post_ymd{
        font-family: $ff_en;
        font-weight: bold;
        text-align: right;
        border-bottom: 1px solid #000;
        @if $type == sp{
            font-size: 14px;
            padding: 10px 0;
        }
        @if $type == pc{
            font-size: 14px;
            padding: 35px 0;
        }
        time{
            position: relative;
            padding-left: 20px;
            &:before{
                content: "";
                display: inline-block;
                background: url(../images/second/time.png) no-repeat;
                width: 11px;
                height: 10px;
                position: absolute;
                top: 4px;
                left: 0;
            }
        }
    }
}

.prev_next_wrap{
    border-top: 1px solid #000;
    display: flex;
    justify-content: space-between;
    @if $type == sp{
        padding: 20px 0 0;
        margin-top: 50px;
    }
    @if $type == pc{
        padding: 30px 0 0;
        margin-top: 110px;
    }
    > div{
        position: relative;
        @if $type == sp{
            width: 45%;
        }
        @if $type == pc{
            width: 50%;
        }
        &.prev{
            a{
                justify-content: flex-start;
                @if $type == sp{
                    padding-left: 20px;
                    flex-direction: column;
                    &:before{
                        content: '';
                        width: 8px;
                        height: 8px;
                        border: 0px;
                        border-bottom: solid 1px $c_base;
                        border-left: solid 1px $c_base;
                        transform: rotate(45deg);
                        position: absolute;
                        top: calc(50% - 4px);
                        left: 0px;
                    }
                }
                @if $type == pc{
                    padding-left: 35px;
                    &:before{
                        content: '';
                        width: 14px;
                        height: 14px;
                        border: 0px;
                        border-bottom: solid 2px $c_base;
                        border-left: solid 2px $c_base;
                        transform: rotate(45deg);
                        position: absolute;
                        top: calc(50% - 7px);
                        left: 0px;
                    }
                }
            }
            
        }
        &.next{
            a{
                justify-content: flex-end;
                @if $type == sp{
                    padding-right: 20px;
                    flex-direction: column-reverse;
                    &:before{
                        content: '';
                        width: 8px;
                        height: 8px;
                        border: 0px;
                        border-top: solid 1px $c_base;
                        border-right: solid 1px $c_base;
                        transform: rotate(45deg);
                        position: absolute;
                        top: calc(50% - 4px);
                        right: 0px;
                    }
                }
                @if $type == pc{
                    padding-right: 35px;
                    &:before{
                        content: '';
                        width: 14px;
                        height: 14px;
                        border: 0px;
                        border-top: solid 2px $c_base;
                        border-right: solid 2px $c_base;
                        transform: rotate(45deg);
                        position: absolute;
                        top: calc(50% - 7px);
                        right: 0px;
                    }
                }
            }
        }
        a{
            display: flex;
            text-decoration: none;
            color: #000;
            position: relative;
            @if $type == sp{
            }
            @if $type == pc{
                transition: .3s;
                align-items: center;
                &:hover{
                    color: $c_base;
                }
            }
            figure{
                @if $type == sp{
                    width: 100%;
                }
                @if $type == pc{
                    width: 150px;
                }
                img{
                    max-width: 100%;
                }
            }
            .title{
                margin: 0;
                font-weight: bold;
                @if $type == sp{
                    line-height: 1.5;
                    margin: 10px 0 0 0;
                    font-size: 10px;
                }
                @if $type == pc{
                    margin: 0 30px;
                }
            }
        }
    }
}

.works_img{
    @if $type == sp{
        margin-top: 35px;
    }
    @if $type == pc{
        display: flex;
        margin-top: 60px;
    }
    > div{
        @if $type == sp{
            &:first-of-type{
                display: flex;
                img{
                    display: block;
                    width: calc(50% - 5px);
                    &:last-of-type{
                        margin-left: 10px;
                    }
                }
            }
            &:last-of-type{
                width: 100%;
                margin-top: 10px;
            }
        }
        @if $type == pc{
            &:first-of-type{
                width: 32%;
                img{
                    display: block;
                    &:last-of-type{
                        margin-top: 28px;
                    }
                }
            }
            &:last-of-type{
                width: 68%;
                margin-left: 30px;
            }
            + p{
                margin-top: 30px;
            }
        }
    }
}

.company_list{
    @if $type == sp{
        margin-top: 40px;
    }
    @if $type == pc{
        margin-top: 90px;
    }
    .item{
        @if $type == sp{
            + .item{
                margin-top: 40px;
            }
        }
        @if $type == pc{
            display: flex;
            align-items: flex-end;
            + .item{
                margin-top: 80px;
            }
            &:nth-of-type(odd){
                .text_wrap{
                    padding-left: 40px;
                }
            }
            &:nth-of-type(even){
                flex-direction: row-reverse;
                .text_wrap{
                    padding-right: 40px;
                }
            }
        }
        figure{
            @if $type == sp{
            }
            @if $type == pc{
                width: calc(100% - 370px);
            }
            img{
                width: 100%;
            }
        }
        .text_wrap{
            @if $type == sp{
                margin-top: 20px;
            }
            @if $type == pc{
                width: 370px;
                
            }
            .title{
                color: #868686;
                font-family: $ff_en;
                margin: 0;
                line-height: 1;
                @if $type == sp{
                    font-size: 40px;
                }
                @if $type == pc{
                    font-size: 72px;
                }
                span{
                    color: $c_base;
                }
            }
            .text{
                @if $type == sp{
                    margin-top: 15px;
                }
                @if $type == pc{
                    margin-top: 30px;
                }
            }
        }
    }
}

.contact_wrap{
    @if $type == sp{
        margin-top: 45px;
    }
    @if $type == pc{
        margin: 90px auto 0;
        max-width: 880px;
    }
    input,
    button,
    select,
    textarea {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: transparent;
        border: none;
        border-radius: 0;
        font: inherit;
        outline: none;
    }

    textarea {
        resize: vertical;
    }

    input[type='checkbox'],
    input[type='radio'] {
        display: none;
    }

    input[type='submit'],
    input[type='button'],
    label,
    button,
    select {
        cursor: pointer;
    }

    select::-ms-expand {
        display: none;
    }

    input,textarea {
        border: 1px solid #868686;
        width: 100%;
        @if $type == sp{
            padding: 10px 10px;
        }
        @if $type == pc{
            padding: 15px 20px;
        }
        &::placeholder {
            color: #ccc;
        }
    }

    input {
        @if $type == sp{
            &.sizeS{
                width: 30%;
            }
            &.sizeM{
                width: 50%;
            }
        }
        @if $type == pc{
            &.sizeS{
                width: 20%;
            }
            &.sizeM{
                width: 40%;
            }
        }
    }

    .mwform-radio-field {
        @if $type == sp{
            display: inline-block;
            margin-right: 15px;
            margin-bottom: 10px;
        }
        @if $type == pc{
            margin-right: 30px;
        }
        input[type="radio"] {
            position: absolute;
            opacity: 0;
            + .mwform-radio-field-text {
                &:before {
                    content: '';
                    background: #ffff;
                    border-radius: 100%;
                    border: 1px solid #ccc;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    position: relative;
                    top: 2px;
                    margin-right: 10px;
                    vertical-align: top;
                    cursor: pointer;
                    text-align: center;
                    transition: all 250ms ease;
                    @if $type == sp{
                        width: 14px;
                        height: 14px;
                    }
                }
            }
            &:checked {
                + .mwform-radio-field-text {
                    &:before {
                        background-color: #ccc;
                        box-shadow: inset 0 0 0 4px #fff;
                        @if $type == sp{
                            box-shadow: inset 0 0 0 3px #fff;
                        }
                    }
                }
            }
        }
    }

    select {
        border: 1px solid $c_gray;
        padding: 10px;
        background: url(../images/common/arrow_btm.png) no-repeat right 15px center;
        padding-right: 30px;
        width: 100%;
        @if $type == sp{
            &.sizeS{
                width: 30%;
            }
            &.sizeM{
                width: 50%;
            }
        }
        @if $type == pc{
            &.sizeS{
                width: 20%;
            }
            &.sizeM{
                width: 40%;
            }
        }
        &::placeholder {
            color: #ccc;
        }
    }

    > dl{
        @if $type == sp{
            &:not(:first-of-type){
                margin-top: 20px;
            }
        }
        @if $type == pc{
            &:not(:first-of-type){
                margin-top: 40px;
            }
        }
        > dt{
            font-weight: bold;
            @if $type == sp{
                margin-bottom: 10px;
            }
            @if $type == pc{
                font-size: 20px;
                padding-right: 50px;
            }
            .hissu{
                line-height: 1;
                padding: 5px 8px;
                font-size: 14px;
                background-color: $c_base;
                color: #fff;
                display: inline-block;
                margin-left: 10px;
            }
        }
        > dd{
            @if $type == sp{
            }
            @if $type == pc{
                width: 100%;
                margin-top: 15px;
            }
            .attention{
                display: block;
                font-size: 12px;
                margin-top: 5px;
            }
            > dl{
                @if $type == sp{
                    &:not(:first-of-type){
                        margin-top: 10px;
                    }
                }
                @if $type == pc{
                    &:not(:first-of-type){
                        margin-top: 20px;
                    }
                }
                > dt{
                    @if $type == sp{
                        margin-bottom: 5px;
                    }
                    @if $type == pc{
                        font-size: 12px;
                        margin-bottom: 5px;
                    }
                }
                > dd{
                    @if $type == sp{
                    }
                    @if $type == pc{
                    }
                }
            }
        }
    }
    .btn_wrap{
        text-align: center;
        @if $type == sp{
            margin-top: 45px;
        }
        @if $type == pc{
            margin-top: 90px;
        }
        .common_btn{
            span{
                margin-top: 0;
                @if $type == sp{
                }
                @if $type == pc{
                    padding: 25px 120px;
                }
            }
            input{
                padding: 0;
                border: none;
                @if $type == sp{
                }
                @if $type == pc{
                    font-size: 24px;
                }
            }
        }
    }
}//contact_wrap

.sample{
	@if $type == sp{
	}
	@if $type == pc{
	}
}
