@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/*テキスト内で使うリンクカラー*/
/* font */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Noto+Sans+JP:wght@400;700;900&family=Oswald:wght@400;700&display=swap");
@media screen and (max-width: 980px) {
  body {
    padding-top: 65px; }
  #footer {
    margin-top: 30px; }
  .common_inner {
    width: calc(100% - 40px);
    margin: 0 auto; }
  .breadcrumbs {
    display: flex;
    flex-wrap: wrap; }
    .breadcrumbs .common_inner {
      padding: 5px 0px; }
      .breadcrumbs .common_inner > span {
        display: inline-block;
        position: relative;
        padding-right: 15px;
        font-size: 10px;
        color: #000;
        font-size: 11px;
        font-size: 14px; }
        .breadcrumbs .common_inner > span:not(:last-of-type)::after {
          content: "/";
          display: inline-block;
          margin-left: 15px; }
        .breadcrumbs .common_inner > span a {
          color: #00a3a8; }
  #page_header {
    background: url(../images/second/h1_bg_sp.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center; }
    #page_header .page_header_title {
      font-weight: bold;
      font-family: "Lato", sans-serif "Noto Sans JP", sans-serif;
      font-size: 21px;
      padding: 0 20px; }
  #contents {
    line-height: 2;
    position: relative;
    padding: 40px 0 100px; }
  .page_catch {
    font-weight: bold;
    border-bottom: 1px solid #000;
    padding-bottom: 30px; }
  .wp-pagenavi {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: "Lato", sans-serif;
    line-height: 1;
    margin-top: 45px; }
    .wp-pagenavi .page, .wp-pagenavi .nextpostslink, .wp-pagenavi .previouspostslink, .wp-pagenavi .extend, .wp-pagenavi .last, .wp-pagenavi .first {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border: 1px solid #00a3a8;
      color: #00a3a8;
      text-decoration: none;
      font-weight: normal;
      min-width: 30px;
      min-height: 30px;
      margin: 0 3px; }
    .wp-pagenavi .extend {
      border: none; }
      .wp-pagenavi .extend:hover {
        background: #fff;
        opacity: 1;
        border: none !important;
        color: #000; }
    .wp-pagenavi span.current {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      background-color: #000;
      color: #fff;
      font-weight: normal;
      min-width: 30px;
      min-height: 30px;
      margin: 0 3px; }
    .wp-pagenavi .previouspostslink {
      position: relative; }
      .wp-pagenavi .previouspostslink:hover:before {
        border-bottom: solid 1px #fff;
        border-left: solid 1px #fff; }
    .wp-pagenavi .nextpostslink {
      position: relative; }
      .wp-pagenavi .nextpostslink:hover:before {
        border-top: solid 1px #fff;
        border-right: solid 1px #fff; }
    .wp-pagenavi .previouspostslink:before {
      content: '';
      width: 6px;
      height: 6px;
      border: 0px;
      border-bottom: solid 1px #00a3a8;
      border-left: solid 1px #00a3a8;
      transform: rotate(45deg);
      position: absolute;
      top: calc(50% - 3px);
      right: 14px;
      right: 9px; }
    .wp-pagenavi .nextpostslink:before {
      content: '';
      width: 6px;
      height: 6px;
      border: 0px;
      border-top: solid 1px #00a3a8;
      border-right: solid 1px #00a3a8;
      transform: rotate(45deg);
      position: absolute;
      top: calc(50% - 3px);
      left: 14px;
      left: 9px; }
  #contents.post {
    background: url(../images/second/h1_bg_sp.png) no-repeat top center;
    background-size: contain; }
    #contents.post .post_h1 {
      font-size: 20px;
      margin-bottom: 30px; }
    #contents.post .post_ymd {
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-align: right;
      border-bottom: 1px solid #000;
      font-size: 14px;
      padding: 10px 0; }
      #contents.post .post_ymd time {
        position: relative;
        padding-left: 20px; }
        #contents.post .post_ymd time:before {
          content: "";
          display: inline-block;
          background: url(../images/second/time.png) no-repeat;
          width: 11px;
          height: 10px;
          position: absolute;
          top: 4px;
          left: 0; }
  .prev_next_wrap {
    border-top: 1px solid #000;
    display: flex;
    justify-content: space-between;
    padding: 20px 0 0;
    margin-top: 50px; }
    .prev_next_wrap > div {
      position: relative;
      width: 45%; }
      .prev_next_wrap > div.prev a {
        justify-content: flex-start;
        padding-left: 20px;
        flex-direction: column; }
        .prev_next_wrap > div.prev a:before {
          content: '';
          width: 8px;
          height: 8px;
          border: 0px;
          border-bottom: solid 1px #00a3a8;
          border-left: solid 1px #00a3a8;
          transform: rotate(45deg);
          position: absolute;
          top: calc(50% - 4px);
          left: 0px; }
      .prev_next_wrap > div.next a {
        justify-content: flex-end;
        padding-right: 20px;
        flex-direction: column-reverse; }
        .prev_next_wrap > div.next a:before {
          content: '';
          width: 8px;
          height: 8px;
          border: 0px;
          border-top: solid 1px #00a3a8;
          border-right: solid 1px #00a3a8;
          transform: rotate(45deg);
          position: absolute;
          top: calc(50% - 4px);
          right: 0px; }
      .prev_next_wrap > div a {
        display: flex;
        text-decoration: none;
        color: #000;
        position: relative; }
        .prev_next_wrap > div a figure {
          width: 100%; }
          .prev_next_wrap > div a figure img {
            max-width: 100%; }
        .prev_next_wrap > div a .title {
          margin: 0;
          font-weight: bold;
          line-height: 1.5;
          margin: 10px 0 0 0;
          font-size: 10px; }
  .works_img {
    margin-top: 35px; }
    .works_img > div:first-of-type {
      display: flex; }
      .works_img > div:first-of-type img {
        display: block;
        width: calc(50% - 5px); }
        .works_img > div:first-of-type img:last-of-type {
          margin-left: 10px; }
    .works_img > div:last-of-type {
      width: 100%;
      margin-top: 10px; }
  .company_list {
    margin-top: 40px; }
    .company_list .item + .item {
      margin-top: 40px; }
    .company_list .item figure img {
      width: 100%; }
    .company_list .item .text_wrap {
      margin-top: 20px; }
      .company_list .item .text_wrap .title {
        color: #868686;
        font-family: "Lato", sans-serif;
        margin: 0;
        line-height: 1;
        font-size: 40px; }
        .company_list .item .text_wrap .title span {
          color: #00a3a8; }
      .company_list .item .text_wrap .text {
        margin-top: 15px; }
  .contact_wrap {
    margin-top: 45px; }
    .contact_wrap input,
    .contact_wrap button,
    .contact_wrap select,
    .contact_wrap textarea {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;
      border: none;
      border-radius: 0;
      font: inherit;
      outline: none; }
    .contact_wrap textarea {
      resize: vertical; }
    .contact_wrap input[type='checkbox'],
    .contact_wrap input[type='radio'] {
      display: none; }
    .contact_wrap input[type='submit'],
    .contact_wrap input[type='button'],
    .contact_wrap label,
    .contact_wrap button,
    .contact_wrap select {
      cursor: pointer; }
    .contact_wrap select::-ms-expand {
      display: none; }
    .contact_wrap input, .contact_wrap textarea {
      border: 1px solid #868686;
      width: 100%;
      padding: 10px 10px; }
      .contact_wrap input::placeholder, .contact_wrap textarea::placeholder {
        color: #ccc; }
    .contact_wrap input.sizeS {
      width: 30%; }
    .contact_wrap input.sizeM {
      width: 50%; }
    .contact_wrap .mwform-radio-field {
      display: inline-block;
      margin-right: 15px;
      margin-bottom: 10px; }
      .contact_wrap .mwform-radio-field input[type="radio"] {
        position: absolute;
        opacity: 0; }
        .contact_wrap .mwform-radio-field input[type="radio"] + .mwform-radio-field-text:before {
          content: '';
          background: #ffff;
          border-radius: 100%;
          border: 1px solid #ccc;
          display: inline-block;
          width: 18px;
          height: 18px;
          position: relative;
          top: 2px;
          margin-right: 10px;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
          width: 14px;
          height: 14px; }
        .contact_wrap .mwform-radio-field input[type="radio"]:checked + .mwform-radio-field-text:before {
          background-color: #ccc;
          box-shadow: inset 0 0 0 4px #fff;
          box-shadow: inset 0 0 0 3px #fff; }
    .contact_wrap select {
      border: 1px solid #868686;
      padding: 10px;
      background: url(../images/common/arrow_btm.png) no-repeat right 15px center;
      padding-right: 30px;
      width: 100%; }
      .contact_wrap select.sizeS {
        width: 30%; }
      .contact_wrap select.sizeM {
        width: 50%; }
      .contact_wrap select::placeholder {
        color: #ccc; }
    .contact_wrap > dl:not(:first-of-type) {
      margin-top: 20px; }
    .contact_wrap > dl > dt {
      font-weight: bold;
      margin-bottom: 10px; }
      .contact_wrap > dl > dt .hissu {
        line-height: 1;
        padding: 5px 8px;
        font-size: 14px;
        background-color: #00a3a8;
        color: #fff;
        display: inline-block;
        margin-left: 10px; }
    .contact_wrap > dl > dd .attention {
      display: block;
      font-size: 12px;
      margin-top: 5px; }
    .contact_wrap > dl > dd > dl:not(:first-of-type) {
      margin-top: 10px; }
    .contact_wrap > dl > dd > dl > dt {
      margin-bottom: 5px; }
    .contact_wrap .btn_wrap {
      text-align: center;
      margin-top: 45px; }
      .contact_wrap .btn_wrap .common_btn span {
        margin-top: 0; }
      .contact_wrap .btn_wrap .common_btn input {
        padding: 0;
        border: none; }
  article {
    line-height: 2; }
    article ul {
      list-style: none; }
    article img {
      max-width: 100%;
      width: auto;
      height: auto; }
      article img.aligncenter {
        margin: 0 auto;
        display: block; }
      article img.alignright {
        margin: 0 0 0 auto;
        display: block; }
    article p, article .commonList, article ol, article .imgLine2, article .imgLine3, article .imgLine4, article .twoCol, article .commonTable, article .colorBox, article .borderBox, article .ytWrap, article .common_dl_wrap, article .link_list, article .info_box {
      margin-top: 30px; }
    article h1, article h2, article h3, article h4 {
      line-height: 1.3; }
    article a {
      color: #00a3a8; }
      article a:hover {
        text-decoration: none; }
    article p.narrow {
      margin-top: 15px; }
    article .ff_en {
      font-family: "Lato", sans-serif; }
    article .common_btn {
      color: #fff;
      text-decoration: none;
      letter-spacing: 0.1em;
      display: inline-block;
      position: relative;
      width: 100%; }
      article .common_btn span {
        display: block;
        position: relative;
        color: #fff;
        background-color: #00a3a8;
        border: 1px solid #00a3a8;
        width: 100%;
        height: 100%;
        font-size: 13px;
        margin-top: 15px;
        padding: 15px 60px;
        z-index: 2; }
        article .common_btn span:before {
          content: "";
          display: inline-block;
          border: 1px solid #fff;
          border-radius: 50%;
          position: absolute;
          background: url(../images/common/arrow_w.png) no-repeat center;
          top: calc(50% - 15px);
          right: 15px;
          width: 30px;
          height: 30px;
          background-size: 8px; }
    article .more_btn {
      text-decoration: none;
      color: #000;
      display: inline-block;
      position: relative;
      font-weight: bold;
      font-family: "Lato", sans-serif;
      margin-top: 30px;
      font-size: 14px;
      padding-left: 65px; }
      article .more_btn:before {
        content: "";
        display: inline-block;
        border: 1px solid #000;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        background: url(../images/common/arrow.png) no-repeat center;
        width: 45px;
        height: 45px;
        background-size: 8px; }
      article .more_btn span {
        display: inline-block;
        background: url(../images/common/more_line.png) no-repeat left bottom;
        line-height: 1;
        padding: 5px 0 20px; }
    article h2.title, article .post h2 {
      color: #00a3a8;
      font-weight: bold;
      text-align: center;
      font-size: 22px;
      padding-top: 20px;
      margin-top: 50px;
      border-top: 2px solid #00a3a8; }
      article h2.title + .h2_en_title, article .post h2 + .h2_en_title {
        text-align: center;
        color: #00a3a8;
        margin-top: 0px;
        font-size: 16px; }
    article h2.title2 {
      font-weight: bold;
      position: relative;
      color: #00a3a8;
      font-size: 22px;
      margin-top: 40px;
      padding-right: 50px; }
      article h2.title2:after {
        content: "";
        display: block;
        background-color: #00a3a8;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        top: calc(50% - 1px); }
      article h2.title2 span {
        display: inline-block;
        background-color: #fff;
        position: relative;
        z-index: 2;
        padding-right: 15px; }
    article h3.title, article .post h3 {
      font-weight: bold;
      position: relative;
      color: #000;
      font-size: 18px;
      margin-top: 40px;
      padding-left: 35px; }
      article h3.title + p, article .post h3 + p {
        margin-top: 15px; }
      article h3.title:before, article .post h3:before {
        content: "";
        display: inline-block;
        border: 2px solid #00a3a8;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px; }
    article section + section {
      margin-top: 70px; }
  .kome {
    display: block;
    padding-left: 1em;
    text-indent: -1em;
    margin-top: 10px;
    font-size: 12px; }
    .kome:before {
      content: "※"; }
    .kome + .kome {
      margin-top: 0; }
  a.icon {
    display: inline-block;
    position: relative;
    padding-right: 20px; }
    a.icon:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: 50% 0;
      position: absolute;
      right: -5px;
      top: .1em; }
    a.icon[href$=pdf]:after {
      background-image: url(../images/common/icon_pdf.png); }
    a.icon[href$=doc]:after, a.icon[href$=docx]:after {
      background-image: url(../images/common/icon_doc.png); }
    a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
      background-image: url(../images/common/icon_xls.png); }
  .ico_blank {
    display: inline-block;
    width: 11px;
    height: 11px;
    background: url("../images/common/icon_blank.svg") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 5px;
    position: relative;
    top: -4px; }
  .imgLine2 li {
    text-align: center;
    margin-top: 20px; }
    .imgLine2 li img {
      max-width: 100%; }
  .imgLine2 .cap {
    display: block;
    text-align: left;
    font-size: 12px;
    margin-top: 5px; }
  .imgLine3 li {
    text-align: center;
    margin-top: 20px; }
    .imgLine3 li img {
      max-width: 100%; }
  .imgLine3 .cap {
    display: block;
    text-align: left;
    font-size: 12px;
    margin-top: 5px; }
  .imgLine4 {
    display: flex;
    flex-wrap: wrap; }
    .imgLine4 li {
      width: calc(50% - 10px);
      margin-right: 20px; }
      .imgLine4 li:nth-of-type(2n) {
        margin-right: 0; }
      .imgLine4 li:nth-child(n + 3) {
        margin-top: 20px; }
      .imgLine4 li img {
        width: 100%; }
    .imgLine4 .cap {
      display: block;
      text-align: left;
      font-size: 12px;
      margin-top: 5px; }
  .imgInP .img {
    display: block;
    margin: 0 auto;
    padding-bottom: 10px; }
    .imgInP .img .cap {
      display: block;
      text-align: left;
      font-size: 13px;
      margin-top: 5px; }
  .imgInP.sp_img_btm {
    display: flex;
    flex-wrap: wrap; }
    .imgInP.sp_img_btm .img {
      display: block;
      order: 2;
      margin: 10px auto 0;
      padding-bottom: 0; }
    .imgInP.sp_img_btm .text {
      flex-basis: 100%;
      order: 1; }
  .twoCol.wide {
    margin-top: 60px; }
  .twoCol.logo .img {
    text-align: center; }
    .twoCol.logo .img img {
      width: 180px; }
  .twoCol .txt *:nth-child(1) {
    margin-top: 0; }
  .twoCol .txt + .img {
    margin-top: 30px; }
  .twoCol .img {
    width: 100%;
    text-align: center; }
    .twoCol .img.round {
      overflow: hidden;
      border-radius: 15px; }
    .twoCol .img + .txt {
      margin-top: 20px; }
    .twoCol .img img {
      width: 100%;
      height: auto; }
    .twoCol .img .cap {
      display: block;
      margin-top: 5px;
      font-size: 12px;
      text-align: left; }
    .twoCol .img .modal_image {
      text-decoration: none; }
      .twoCol .img .modal_image span {
        display: block;
        margin-top: 5px;
        text-align: right;
        font-size: 10px; }
    .twoCol .img .modal_slide:not(:first-of-type) {
      display: none; }
  .twoCol.sp_reverse {
    display: flex;
    flex-direction: column-reverse; }
    .twoCol.sp_reverse > div:last-of-type {
      margin-bottom: 20px; }
    .twoCol.sp_reverse .img {
      margin-top: 0; }
  .twoCol .twoCol_title {
    font-weight: bold;
    font-size: 22px;
    position: relative;
    padding-top: 26px;
    padding-left: 26px;
    padding-right: 26px; }
    .twoCol .twoCol_title:before {
      content: "";
      display: inline-block;
      background: url(../images/common/h2_kazari.png) no-repeat;
      width: 26px;
      height: 26px;
      position: absolute;
      top: 0;
      left: 0px; }
    .twoCol .twoCol_title + p {
      margin-top: 15px;
      padding-left: 26px;
      padding-right: 26px; }
  .twoCol .twoCol_title_2 {
    font-weight: bold;
    font-size: 22px;
    position: relative;
    padding-left: 55px; }
    .twoCol .twoCol_title_2 .num {
      font-family: "Oswald", sans-serif;
      font-size: 36px;
      color: #868686;
      position: absolute;
      top: 0px;
      left: 0;
      font-weight: normal;
      line-height: 1; }
      .twoCol .twoCol_title_2 .num span {
        color: #00a3a8; }
    .twoCol .twoCol_title_2 + p {
      margin-top: 15px; }
  ul.commonList li {
    padding-left: 15px;
    position: relative;
    box-sizing: border-box; }
    ul.commonList li:not(:first-of-type) {
      margin-top: 10px; }
    ul.commonList li:before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      margin: auto;
      content: '';
      border-radius: 50%;
      background-color: #00a3a8;
      position: absolute;
      left: 0;
      top: 14px; }
  ol {
    padding-left: 18px; }
    ol li + li {
      margin-top: 10px; }
  .commonTable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: inherit;
    background-color: #FAFAFA; }
    .commonTable td.wFix,
    .commonTable th.wFix {
      width: 120px; }
    .commonTable th {
      color: #000;
      text-align: left;
      font-weight: normal;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
      background-color: #F5F6F6;
      font-size: 13px;
      padding: 12px 15px; }
      .commonTable th.gray {
        background-color: #F5F6F6; }
      .commonTable th:not(:first-of-type) {
        border-left: 1px solid #868686; }
      .commonTable th.null {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        background-color: #fff; }
    .commonTable td {
      padding: 12px;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
      word-break: break-all;
      font-size: 13px;
      padding: 12px 15px; }
      .commonTable td.gray {
        background-color: #F5F6F6; }
      .commonTable td *:last-child {
        margin-bottom: 0; }
      .commonTable td *:first-child {
        margin-top: 0; }
      .commonTable td:not(:first-of-type) {
        border-left: 1px solid #fff; }
      .commonTable td.null {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff; }
  .scrollTable {
    overflow: auto; }
    .scrollTable table {
      white-space: nowrap; }
  .scrolltext {
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 20px 0 -10px; }
  .colorBox {
    background-color: #f5f5f5;
    padding: 20px 20px; }
    .colorBox *:nth-child(1) {
      margin-top: 0; }
    .colorBox .title {
      display: block;
      font-size: 16px;
      margin-bottom: 5px; }
  .borderBox {
    border: 1px solid #00a3a8;
    padding: 20px; }
    .borderBox + .borderBox {
      margin-top: 15px; }
    .borderBox *:nth-child(1) {
      margin-top: 0; }
    .borderBox .borderBoxTitle {
      font-weight: bold;
      font-size: 16px; }
      .borderBox .borderBoxTitle + p {
        margin-top: 10px; }
  .border-box {
    border: 1px solid #ccc;
    padding: 20px; }
  .common_dl_wrap dl {
    padding: 15px 0; }
    .common_dl_wrap dl:not(:first-of-type) {
      border-top: 1px solid #868686; }
    .common_dl_wrap dl dt {
      font-weight: bold;
      margin-bottom: 5px; }
  .common_dl_wrap.small {
    margin-top: 10px; }
    .common_dl_wrap.small dl {
      padding: 10px 0; }
      .common_dl_wrap.small dl:not(:first-of-type) {
        border-top: none; }
      .common_dl_wrap.small dl dt {
        margin-bottom: 5px; }
  .common_dl_wrap.big {
    border-top: 1px solid #868686;
    border-bottom: 1px solid #868686; }
    .common_dl_wrap.big dl dt {
      font-size: 18px;
      margin-bottom: 10px; }
    .common_dl_wrap.big.type2 dl dt {
      font-size: 13px; }
  .ytWrap .ytWrap_inner {
    position: relative;
    padding-bottom: 56.25%; }
  .ytWrap iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  /* common class */
  strong {
    font-weight: bold !important; }
  .taC {
    text-align: center !important; }
  .taL {
    text-align: left !important; }
  .taR {
    text-align: right !important; }
  .Center {
    margin: 0 auto !important; }
  .ovh {
    overflow: hidden !important; }
  .mw100 {
    max-width: 100% !important;
    width: auto !important; }
  .w100p {
    width: 100% !important; }
  .inline {
    display: inline !important; }
  .mB0 {
    margin-bottom: 0 !important; }
  .mB5 {
    margin-bottom: 5px !important; }
  .mB10 {
    margin-bottom: 10px !important; }
  .mB20 {
    margin-bottom: 20px !important; }
  .mB30 {
    margin-bottom: 30px !important; }
  .mB40 {
    margin-bottom: 40px !important; }
  .mB50 {
    margin-bottom: 50px !important; }
  .mT0 {
    margin-top: 0 !important; }
  .mT5 {
    margin-top: 5px !important; }
  .mT10 {
    margin-top: 10px !important; }
  .mT20 {
    margin-top: 20px !important; }
  .mT30 {
    margin-top: 30px !important; }
  .mT40 {
    margin-top: 40px !important; }
  .mT50 {
    margin-top: 50px !important; }
  .mL0 {
    margin-left: 0px !important; }
  .mL5 {
    margin-left: 5px !important; }
  .mL10 {
    margin-left: 10px !important; }
  .mL20 {
    margin-left: 20px !important; }
  .mL30 {
    margin-left: 30px !important; }
  .mL40 {
    margin-left: 40px !important; }
  .mL50 {
    margin-left: 50px !important; }
  .mR0 {
    margin-right: 0px !important; }
  .mR5 {
    margin-right: 5px !important; }
  .mR10 {
    margin-right: 10px !important; }
  .mR20 {
    margin-right: 20px !important; }
  .mR30 {
    margin-right: 30px !important; }
  .mR40 {
    margin-right: 40px !important; }
  .mR50 {
    margin-right: 50px !important; }
  .mB0 {
    margin-bottom: 0 !important; }
  .mB5_sp {
    margin-bottom: 5px !important; }
  .mB10_sp {
    margin-bottom: 10px !important; }
  .mB20_sp {
    margin-bottom: 20px !important; }
  .mB30_sp {
    margin-bottom: 30px !important; }
  .mB40_sp {
    margin-bottom: 40px !important; }
  .mB50_sp {
    margin-bottom: 50px !important; }
  .mT0 {
    margin-top: 0 !important; }
  .mT5_sp {
    margin-top: 5px !important; }
  .mT10_sp {
    margin-top: 10px !important; }
  .mT20_sp {
    margin-top: 20px !important; }
  .mT30_sp {
    margin-top: 30px !important; }
  .mT40_sp {
    margin-top: 40px !important; }
  .mT50_sp {
    margin-top: 50px !important; }
  .mL0_sp {
    margin-left: 0px !important; }
  .mL5_sp {
    margin-left: 5px !important; }
  .mL10_sp {
    margin-left: 10px !important; }
  .mL20_sp {
    margin-left: 20px !important; }
  .mL30_sp {
    margin-left: 30px !important; }
  .mL40_sp {
    margin-left: 40px !important; }
  .mL50_sp {
    margin-left: 50px !important; }
  .mR0_sp {
    margin-right: 0px !important; }
  .mR5_sp {
    margin-right: 5px !important; }
  .mR10_sp {
    margin-right: 10px !important; }
  .mR20_sp {
    margin-right: 20px !important; }
  .mR30_sp {
    margin-right: 30px !important; }
  .mR40_sp {
    margin-right: 40px !important; }
  .mR50_sp {
    margin-right: 50px !important; }
  .size12_sp {
    font-size: 12px !important; }
  .size14_sp {
    font-size: 14px !important; }
  .size16_sp {
    font-size: 16px !important; }
  .size18_sp {
    font-size: 18px !important; }
  .size20_sp {
    font-size: 20px !important; }
  .w50p_sp {
    width: 50% !important; }
  .textred {
    color: red !important; }
  .textblue {
    color: blue !important; }
  .bold {
    font-weight: bold !important; }
  .underline {
    text-decoration: underline !important; }
  .bdnone {
    border: none !important; }
  .floatL {
    float: left; }
  .floatR {
    float: right; }
  .preBox {
    background: #eff0f4;
    border: 1px solid #00a3a8;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8; }
    .preBox pre {
      overflow: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align-last: auto; } }

@media screen and (min-width: 980px) {
  .breadcrumbs {
    display: flex;
    flex-wrap: wrap; }
    .breadcrumbs .common_inner > span {
      display: inline-block;
      position: relative;
      padding-right: 15px;
      font-size: 10px;
      color: #000; }
      .breadcrumbs .common_inner > span:not(:last-of-type)::after {
        content: "/";
        display: inline-block;
        margin-left: 15px; }
      .breadcrumbs .common_inner > span a {
        color: #00a3a8; }
  #page_header .page_header_title {
    font-weight: bold;
    font-family: "Lato", sans-serif "Noto Sans JP", sans-serif; }
  #contents {
    line-height: 2;
    position: relative; }
  .page_catch {
    font-weight: bold;
    border-bottom: 1px solid #000; }
  .wp-pagenavi {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: "Lato", sans-serif;
    line-height: 1; }
    .wp-pagenavi .page, .wp-pagenavi .nextpostslink, .wp-pagenavi .previouspostslink, .wp-pagenavi .extend, .wp-pagenavi .last, .wp-pagenavi .first {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border: 1px solid #00a3a8;
      color: #00a3a8;
      text-decoration: none;
      font-weight: normal; }
    .wp-pagenavi .extend {
      border: none; }
      .wp-pagenavi .extend:hover {
        background: #fff;
        opacity: 1;
        border: none !important;
        color: #000; }
    .wp-pagenavi span.current {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      background-color: #000;
      color: #fff;
      font-weight: normal; }
    .wp-pagenavi .previouspostslink {
      position: relative; }
      .wp-pagenavi .previouspostslink:hover:before {
        border-bottom: solid 1px #fff;
        border-left: solid 1px #fff; }
    .wp-pagenavi .nextpostslink {
      position: relative; }
      .wp-pagenavi .nextpostslink:hover:before {
        border-top: solid 1px #fff;
        border-right: solid 1px #fff; }
    .wp-pagenavi .previouspostslink:before {
      content: '';
      width: 6px;
      height: 6px;
      border: 0px;
      border-bottom: solid 1px #00a3a8;
      border-left: solid 1px #00a3a8;
      transform: rotate(45deg);
      position: absolute;
      top: calc(50% - 3px);
      right: 14px; }
    .wp-pagenavi .nextpostslink:before {
      content: '';
      width: 6px;
      height: 6px;
      border: 0px;
      border-top: solid 1px #00a3a8;
      border-right: solid 1px #00a3a8;
      transform: rotate(45deg);
      position: absolute;
      top: calc(50% - 3px);
      left: 14px; }
  #contents.post .post_ymd {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    text-align: right;
    border-bottom: 1px solid #000; }
    #contents.post .post_ymd time {
      position: relative;
      padding-left: 20px; }
      #contents.post .post_ymd time:before {
        content: "";
        display: inline-block;
        background: url(../images/second/time.png) no-repeat;
        width: 11px;
        height: 10px;
        position: absolute;
        top: 4px;
        left: 0; }
  .prev_next_wrap {
    border-top: 1px solid #000;
    display: flex;
    justify-content: space-between; }
    .prev_next_wrap > div {
      position: relative; }
      .prev_next_wrap > div.prev a {
        justify-content: flex-start; }
      .prev_next_wrap > div.next a {
        justify-content: flex-end; }
      .prev_next_wrap > div a {
        display: flex;
        text-decoration: none;
        color: #000;
        position: relative; }
        .prev_next_wrap > div a figure img {
          max-width: 100%; }
        .prev_next_wrap > div a .title {
          margin: 0;
          font-weight: bold; }
  .company_list .item figure img {
    width: 100%; }
  .company_list .item .text_wrap .title {
    color: #868686;
    font-family: "Lato", sans-serif;
    margin: 0;
    line-height: 1; }
    .company_list .item .text_wrap .title span {
      color: #00a3a8; }
  .contact_wrap input,
  .contact_wrap button,
  .contact_wrap select,
  .contact_wrap textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none; }
  .contact_wrap textarea {
    resize: vertical; }
  .contact_wrap input[type='checkbox'],
  .contact_wrap input[type='radio'] {
    display: none; }
  .contact_wrap input[type='submit'],
  .contact_wrap input[type='button'],
  .contact_wrap label,
  .contact_wrap button,
  .contact_wrap select {
    cursor: pointer; }
  .contact_wrap select::-ms-expand {
    display: none; }
  .contact_wrap input, .contact_wrap textarea {
    border: 1px solid #868686;
    width: 100%; }
    .contact_wrap input::placeholder, .contact_wrap textarea::placeholder {
      color: #ccc; }
  .contact_wrap .mwform-radio-field input[type="radio"] {
    position: absolute;
    opacity: 0; }
    .contact_wrap .mwform-radio-field input[type="radio"] + .mwform-radio-field-text:before {
      content: '';
      background: #ffff;
      border-radius: 100%;
      border: 1px solid #ccc;
      display: inline-block;
      width: 18px;
      height: 18px;
      position: relative;
      top: 2px;
      margin-right: 10px;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease; }
    .contact_wrap .mwform-radio-field input[type="radio"]:checked + .mwform-radio-field-text:before {
      background-color: #ccc;
      box-shadow: inset 0 0 0 4px #fff; }
  .contact_wrap select {
    border: 1px solid #868686;
    padding: 10px;
    background: url(../images/common/arrow_btm.png) no-repeat right 15px center;
    padding-right: 30px;
    width: 100%; }
    .contact_wrap select::placeholder {
      color: #ccc; }
  .contact_wrap > dl > dt {
    font-weight: bold; }
    .contact_wrap > dl > dt .hissu {
      line-height: 1;
      padding: 5px 8px;
      font-size: 14px;
      background-color: #00a3a8;
      color: #fff;
      display: inline-block;
      margin-left: 10px; }
  .contact_wrap > dl > dd .attention {
    display: block;
    font-size: 12px;
    margin-top: 5px; }
  .contact_wrap .btn_wrap {
    text-align: center; }
    .contact_wrap .btn_wrap .common_btn span {
      margin-top: 0; }
    .contact_wrap .btn_wrap .common_btn input {
      padding: 0;
      border: none; }
  article {
    line-height: 2; }
    article ul {
      list-style: none; }
    article img {
      max-width: 100%;
      width: auto;
      height: auto; }
      article img.aligncenter {
        margin: 0 auto;
        display: block; }
      article img.alignright {
        margin: 0 0 0 auto;
        display: block; }
    article h1, article h2, article h3, article h4 {
      line-height: 1.3; }
    article a {
      color: #00a3a8; }
      article a:hover {
        text-decoration: none; }
    article .ff_en {
      font-family: "Lato", sans-serif; }
    article .common_btn {
      color: #fff;
      text-decoration: none;
      letter-spacing: 0.1em;
      display: inline-block;
      position: relative; }
      article .common_btn span {
        display: block;
        position: relative;
        color: #fff;
        background-color: #00a3a8;
        border: 1px solid #00a3a8;
        width: 100%;
        height: 100%; }
        article .common_btn span:before {
          content: "";
          display: inline-block;
          border: 1px solid #fff;
          border-radius: 50%;
          position: absolute;
          background: url(../images/common/arrow_w.png) no-repeat center; }
    article .more_btn {
      text-decoration: none;
      color: #000;
      display: inline-block;
      position: relative;
      font-weight: bold;
      font-family: "Lato", sans-serif; }
      article .more_btn:before {
        content: "";
        display: inline-block;
        border: 1px solid #000;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        background: url(../images/common/arrow.png) no-repeat center; }
      article .more_btn span {
        display: inline-block;
        background: url(../images/common/more_line.png) no-repeat left bottom;
        line-height: 1; }
    article h2.title, article .post h2 {
      color: #00a3a8;
      font-weight: bold;
      text-align: center; }
    article h2.title2 {
      font-weight: bold;
      position: relative;
      color: #00a3a8; }
      article h2.title2:after {
        content: "";
        display: block;
        background-color: #00a3a8;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        top: calc(50% - 1px); }
      article h2.title2 span {
        display: inline-block;
        background-color: #fff;
        position: relative;
        z-index: 2; }
    article h3.title, article .post h3 {
      font-weight: bold;
      position: relative;
      color: #000; }
      article h3.title:before, article .post h3:before {
        content: "";
        display: inline-block;
        border: 2px solid #00a3a8;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0; }
  .kome {
    display: block;
    padding-left: 1em;
    text-indent: -1em;
    margin-top: 10px; }
    .kome:before {
      content: "※"; }
    .kome + .kome {
      margin-top: 0; }
  a.icon {
    display: inline-block;
    position: relative;
    padding-right: 20px; }
    a.icon:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: 50% 0;
      position: absolute;
      right: -5px;
      top: .1em; }
    a.icon[href$=pdf]:after {
      background-image: url(../images/common/icon_pdf.png); }
    a.icon[href$=doc]:after, a.icon[href$=docx]:after {
      background-image: url(../images/common/icon_doc.png); }
    a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
      background-image: url(../images/common/icon_xls.png); }
  .ico_blank {
    display: inline-block;
    width: 11px;
    height: 11px;
    background: url("../images/common/icon_blank.svg") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 5px;
    position: relative;
    top: -4px; }
  .imgLine2 li {
    text-align: center; }
    .imgLine2 li img {
      max-width: 100%; }
  .imgLine2 .cap {
    display: block;
    text-align: left;
    font-size: 12px;
    margin-top: 5px; }
  .imgLine3 li {
    text-align: center; }
    .imgLine3 li img {
      max-width: 100%; }
  .imgLine3 .cap {
    display: block;
    text-align: left;
    font-size: 12px;
    margin-top: 5px; }
  .imgLine4 li img {
    width: 100%; }
  .imgLine4 .cap {
    display: block;
    text-align: left;
    font-size: 12px;
    margin-top: 5px; }
  .imgInP .img .cap {
    display: block;
    text-align: left;
    font-size: 13px;
    margin-top: 5px; }
  ul.commonList li {
    padding-left: 15px;
    position: relative;
    box-sizing: border-box; }
    ul.commonList li:not(:first-of-type) {
      margin-top: 10px; }
    ul.commonList li:before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      margin: auto;
      content: '';
      border-radius: 50%;
      background-color: #00a3a8;
      position: absolute;
      left: 0;
      top: 14px; }
  ol {
    padding-left: 18px; }
    ol li + li {
      margin-top: 10px; }
  .commonTable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: inherit;
    background-color: #FAFAFA; }
    .commonTable th {
      color: #000;
      text-align: left;
      font-weight: normal;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
      background-color: #F5F6F6; }
      .commonTable th.gray {
        background-color: #F5F6F6; }
      .commonTable th.null {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        background-color: #fff; }
    .commonTable td {
      padding: 12px;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
      word-break: break-all; }
      .commonTable td.gray {
        background-color: #F5F6F6; }
      .commonTable td *:last-child {
        margin-bottom: 0; }
      .commonTable td *:first-child {
        margin-top: 0; }
      .commonTable td.null {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff; }
  .scrollTable {
    overflow: auto; }
  .scrolltext {
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 20px 0 -10px; }
  .colorBox {
    background-color: #f5f5f5; }
    .colorBox *:nth-child(1) {
      margin-top: 0; }
    .colorBox .title {
      display: block; }
  .borderBox {
    border: 1px solid #00a3a8; }
    .borderBox *:nth-child(1) {
      margin-top: 0; }
    .borderBox .borderBoxTitle {
      font-weight: bold; }
      .borderBox .borderBoxTitle + p {
        margin-top: 10px; }
  .border-box {
    border: 1px solid #ccc;
    padding: 20px; }
  .common_dl_wrap dl:not(:first-of-type) {
    border-top: 1px solid #868686; }
  .common_dl_wrap dl dt {
    font-weight: bold; }
  .common_dl_wrap.small {
    margin-top: 10px; }
    .common_dl_wrap.small dl:not(:first-of-type) {
      border-top: none; }
  .common_dl_wrap.big {
    border-top: 1px solid #868686;
    border-bottom: 1px solid #868686; }
  .ytWrap .ytWrap_inner {
    position: relative;
    padding-bottom: 56.25%; }
  .ytWrap iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  /* common class */
  strong {
    font-weight: bold !important; }
  .taC {
    text-align: center !important; }
  .taL {
    text-align: left !important; }
  .taR {
    text-align: right !important; }
  .Center {
    margin: 0 auto !important; }
  .ovh {
    overflow: hidden !important; }
  .mw100 {
    max-width: 100% !important;
    width: auto !important; }
  .w100p {
    width: 100% !important; }
  .inline {
    display: inline !important; }
  .mB0 {
    margin-bottom: 0 !important; }
  .mB5 {
    margin-bottom: 5px !important; }
  .mB10 {
    margin-bottom: 10px !important; }
  .mB20 {
    margin-bottom: 20px !important; }
  .mB30 {
    margin-bottom: 30px !important; }
  .mB40 {
    margin-bottom: 40px !important; }
  .mB50 {
    margin-bottom: 50px !important; }
  .mT0 {
    margin-top: 0 !important; }
  .mT5 {
    margin-top: 5px !important; }
  .mT10 {
    margin-top: 10px !important; }
  .mT20 {
    margin-top: 20px !important; }
  .mT30 {
    margin-top: 30px !important; }
  .mT40 {
    margin-top: 40px !important; }
  .mT50 {
    margin-top: 50px !important; }
  .mL0 {
    margin-left: 0px !important; }
  .mL5 {
    margin-left: 5px !important; }
  .mL10 {
    margin-left: 10px !important; }
  .mL20 {
    margin-left: 20px !important; }
  .mL30 {
    margin-left: 30px !important; }
  .mL40 {
    margin-left: 40px !important; }
  .mL50 {
    margin-left: 50px !important; }
  .mR0 {
    margin-right: 0px !important; }
  .mR5 {
    margin-right: 5px !important; }
  .mR10 {
    margin-right: 10px !important; }
  .mR20 {
    margin-right: 20px !important; }
  .mR30 {
    margin-right: 30px !important; }
  .mR40 {
    margin-right: 40px !important; }
  .mR50 {
    margin-right: 50px !important; }
  .textred {
    color: red !important; }
  .textblue {
    color: blue !important; }
  .bold {
    font-weight: bold !important; }
  .underline {
    text-decoration: underline !important; }
  .bdnone {
    border: none !important; }
  .floatL {
    float: left; }
  .floatR {
    float: right; }
  .preBox {
    background: #eff0f4;
    border: 1px solid #00a3a8;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8; }
    .preBox pre {
      overflow: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align-last: auto; } }

@media print, screen and (min-width: 981px) {
  body {
    padding-top: 130px; }
  #footer {
    margin-top: 80px; }
  .common_inner {
    width: 96%;
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 60px; }
    .common_inner.wide {
      max-width: 1320px; }
  .breadcrumbs {
    display: flex;
    flex-wrap: wrap; }
    .breadcrumbs .common_inner {
      padding: 10px 0px; }
      .breadcrumbs .common_inner > span {
        display: inline-block;
        position: relative;
        padding-right: 15px;
        font-size: 10px;
        color: #000; }
        .breadcrumbs .common_inner > span:not(:last-of-type)::after {
          content: "/";
          display: inline-block;
          margin-left: 15px; }
        .breadcrumbs .common_inner > span a {
          color: #00a3a8; }
  #page_header {
    background: url(../images/second/h1_bg.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center; }
    #page_header .page_header_title {
      font-weight: bold;
      font-family: "Lato", sans-serif "Noto Sans JP", sans-serif;
      font-size: 40px;
      width: 96%;
      max-width: 1260px;
      margin: 0 auto;
      padding: 0 60px 0 0; }
  #contents {
    line-height: 2;
    position: relative;
    padding: 70px 0 100px;
    background-image: url(../images/second/contens_bg_2.png), url(../images/second/contens_bg_1.png);
    background-position: right 0% top 25%,left 0% top 50%;
    background-repeat: no-repeat; }
  .page_catch {
    font-weight: bold;
    border-bottom: 1px solid #000;
    font-size: 18px;
    padding-bottom: 50px; }
  .wp-pagenavi {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: "Lato", sans-serif;
    line-height: 1;
    margin-top: 90px; }
    .wp-pagenavi .page, .wp-pagenavi .nextpostslink, .wp-pagenavi .previouspostslink, .wp-pagenavi .extend, .wp-pagenavi .last, .wp-pagenavi .first {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border: 1px solid #00a3a8;
      color: #00a3a8;
      text-decoration: none;
      font-weight: normal;
      min-width: 40px;
      min-height: 40px;
      transition: .4s;
      margin: 0 5px; }
      .wp-pagenavi .page:hover, .wp-pagenavi .nextpostslink:hover, .wp-pagenavi .previouspostslink:hover, .wp-pagenavi .extend:hover, .wp-pagenavi .last:hover, .wp-pagenavi .first:hover {
        color: #fff;
        background-color: #000;
        border: 1px solid #000;
        opacity: 1; }
    .wp-pagenavi .extend {
      border: none; }
      .wp-pagenavi .extend:hover {
        background: #fff;
        opacity: 1;
        border: none !important;
        color: #000; }
    .wp-pagenavi span.current {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      background-color: #000;
      color: #fff;
      font-weight: normal;
      min-width: 40px;
      min-height: 40px;
      margin: 0 5px; }
    .wp-pagenavi .previouspostslink {
      position: relative; }
      .wp-pagenavi .previouspostslink:hover:before {
        border-bottom: solid 1px #fff;
        border-left: solid 1px #fff; }
    .wp-pagenavi .nextpostslink {
      position: relative; }
      .wp-pagenavi .nextpostslink:hover:before {
        border-top: solid 1px #fff;
        border-right: solid 1px #fff; }
    .wp-pagenavi .previouspostslink:before {
      content: '';
      width: 6px;
      height: 6px;
      border: 0px;
      border-bottom: solid 1px #00a3a8;
      border-left: solid 1px #00a3a8;
      transform: rotate(45deg);
      position: absolute;
      top: calc(50% - 3px);
      right: 14px; }
    .wp-pagenavi .nextpostslink:before {
      content: '';
      width: 6px;
      height: 6px;
      border: 0px;
      border-top: solid 1px #00a3a8;
      border-right: solid 1px #00a3a8;
      transform: rotate(45deg);
      position: absolute;
      top: calc(50% - 3px);
      left: 14px; }
  #contents.post {
    background: url(../images/second/post_h1_bg.png) no-repeat top center;
    padding-top: 80px; }
    #contents.post .post_h1 {
      font-size: 34px;
      margin-bottom: 70px; }
    #contents.post .post_ymd {
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-align: right;
      border-bottom: 1px solid #000;
      font-size: 14px;
      padding: 35px 0; }
      #contents.post .post_ymd time {
        position: relative;
        padding-left: 20px; }
        #contents.post .post_ymd time:before {
          content: "";
          display: inline-block;
          background: url(../images/second/time.png) no-repeat;
          width: 11px;
          height: 10px;
          position: absolute;
          top: 4px;
          left: 0; }
  .prev_next_wrap {
    border-top: 1px solid #000;
    display: flex;
    justify-content: space-between;
    padding: 30px 0 0;
    margin-top: 110px; }
    .prev_next_wrap > div {
      position: relative;
      width: 50%; }
      .prev_next_wrap > div.prev a {
        justify-content: flex-start;
        padding-left: 35px; }
        .prev_next_wrap > div.prev a:before {
          content: '';
          width: 14px;
          height: 14px;
          border: 0px;
          border-bottom: solid 2px #00a3a8;
          border-left: solid 2px #00a3a8;
          transform: rotate(45deg);
          position: absolute;
          top: calc(50% - 7px);
          left: 0px; }
      .prev_next_wrap > div.next a {
        justify-content: flex-end;
        padding-right: 35px; }
        .prev_next_wrap > div.next a:before {
          content: '';
          width: 14px;
          height: 14px;
          border: 0px;
          border-top: solid 2px #00a3a8;
          border-right: solid 2px #00a3a8;
          transform: rotate(45deg);
          position: absolute;
          top: calc(50% - 7px);
          right: 0px; }
      .prev_next_wrap > div a {
        display: flex;
        text-decoration: none;
        color: #000;
        position: relative;
        transition: .3s;
        align-items: center; }
        .prev_next_wrap > div a:hover {
          color: #00a3a8; }
        .prev_next_wrap > div a figure {
          width: 150px; }
          .prev_next_wrap > div a figure img {
            max-width: 100%; }
        .prev_next_wrap > div a .title {
          margin: 0;
          font-weight: bold;
          margin: 0 30px; }
  .works_img {
    display: flex;
    margin-top: 60px; }
    .works_img > div:first-of-type {
      width: 32%; }
      .works_img > div:first-of-type img {
        display: block; }
        .works_img > div:first-of-type img:last-of-type {
          margin-top: 28px; }
    .works_img > div:last-of-type {
      width: 68%;
      margin-left: 30px; }
    .works_img > div + p {
      margin-top: 30px; }
  .company_list {
    margin-top: 90px; }
    .company_list .item {
      display: flex;
      align-items: flex-end; }
      .company_list .item + .item {
        margin-top: 80px; }
      .company_list .item:nth-of-type(odd) .text_wrap {
        padding-left: 40px; }
      .company_list .item:nth-of-type(even) {
        flex-direction: row-reverse; }
        .company_list .item:nth-of-type(even) .text_wrap {
          padding-right: 40px; }
      .company_list .item figure {
        width: calc(100% - 370px); }
        .company_list .item figure img {
          width: 100%; }
      .company_list .item .text_wrap {
        width: 370px; }
        .company_list .item .text_wrap .title {
          color: #868686;
          font-family: "Lato", sans-serif;
          margin: 0;
          line-height: 1;
          font-size: 72px; }
          .company_list .item .text_wrap .title span {
            color: #00a3a8; }
        .company_list .item .text_wrap .text {
          margin-top: 30px; }
  .contact_wrap {
    margin: 90px auto 0;
    max-width: 880px; }
    .contact_wrap input,
    .contact_wrap button,
    .contact_wrap select,
    .contact_wrap textarea {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;
      border: none;
      border-radius: 0;
      font: inherit;
      outline: none; }
    .contact_wrap textarea {
      resize: vertical; }
    .contact_wrap input[type='checkbox'],
    .contact_wrap input[type='radio'] {
      display: none; }
    .contact_wrap input[type='submit'],
    .contact_wrap input[type='button'],
    .contact_wrap label,
    .contact_wrap button,
    .contact_wrap select {
      cursor: pointer; }
    .contact_wrap select::-ms-expand {
      display: none; }
    .contact_wrap input, .contact_wrap textarea {
      border: 1px solid #868686;
      width: 100%;
      padding: 15px 20px; }
      .contact_wrap input::placeholder, .contact_wrap textarea::placeholder {
        color: #ccc; }
    .contact_wrap input.sizeS {
      width: 20%; }
    .contact_wrap input.sizeM {
      width: 40%; }
    .contact_wrap .mwform-radio-field {
      margin-right: 30px; }
      .contact_wrap .mwform-radio-field input[type="radio"] {
        position: absolute;
        opacity: 0; }
        .contact_wrap .mwform-radio-field input[type="radio"] + .mwform-radio-field-text:before {
          content: '';
          background: #ffff;
          border-radius: 100%;
          border: 1px solid #ccc;
          display: inline-block;
          width: 18px;
          height: 18px;
          position: relative;
          top: 2px;
          margin-right: 10px;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease; }
        .contact_wrap .mwform-radio-field input[type="radio"]:checked + .mwform-radio-field-text:before {
          background-color: #ccc;
          box-shadow: inset 0 0 0 4px #fff; }
    .contact_wrap select {
      border: 1px solid #868686;
      padding: 10px;
      background: url(../images/common/arrow_btm.png) no-repeat right 15px center;
      padding-right: 30px;
      width: 100%; }
      .contact_wrap select.sizeS {
        width: 20%; }
      .contact_wrap select.sizeM {
        width: 40%; }
      .contact_wrap select::placeholder {
        color: #ccc; }
    .contact_wrap > dl:not(:first-of-type) {
      margin-top: 40px; }
    .contact_wrap > dl > dt {
      font-weight: bold;
      font-size: 20px;
      padding-right: 50px; }
      .contact_wrap > dl > dt .hissu {
        line-height: 1;
        padding: 5px 8px;
        font-size: 14px;
        background-color: #00a3a8;
        color: #fff;
        display: inline-block;
        margin-left: 10px; }
    .contact_wrap > dl > dd {
      width: 100%;
      margin-top: 15px; }
      .contact_wrap > dl > dd .attention {
        display: block;
        font-size: 12px;
        margin-top: 5px; }
      .contact_wrap > dl > dd > dl:not(:first-of-type) {
        margin-top: 20px; }
      .contact_wrap > dl > dd > dl > dt {
        font-size: 12px;
        margin-bottom: 5px; }
    .contact_wrap .btn_wrap {
      text-align: center;
      margin-top: 90px; }
      .contact_wrap .btn_wrap .common_btn span {
        margin-top: 0;
        padding: 25px 120px; }
      .contact_wrap .btn_wrap .common_btn input {
        padding: 0;
        border: none;
        font-size: 24px; }
  article {
    line-height: 2; }
    article ul {
      list-style: none; }
    article img {
      max-width: 100%;
      width: auto;
      height: auto; }
      article img.aligncenter {
        margin: 0 auto;
        display: block; }
      article img.alignright {
        margin: 0 0 0 auto;
        display: block; }
    article p, article .commonList, article ol, article .imgLine2, article .imgLine3, article .imgLine4, article .twoCol, article .commonTable, article .colorBox, article .borderBox, article .ytWrap, article .common_dl_wrap, article .link_list, article .info_box {
      margin-top: 60px; }
    article h1, article h2, article h3, article h4 {
      line-height: 1.3; }
    article a {
      color: #00a3a8; }
      article a:hover {
        text-decoration: none; }
    article p.narrow {
      margin-top: 30px; }
    article .ff_en {
      font-family: "Lato", sans-serif; }
    article .common_btn {
      color: #fff;
      text-decoration: none;
      letter-spacing: 0.1em;
      display: inline-block;
      position: relative; }
      article .common_btn:hover span:before {
        background: url(../images/common/arrow_blue.png) no-repeat #fff center; }
      article .common_btn:before {
        content: "";
        display: inline-block;
        background: url(../images/common/botton_shadow.png) no-repeat;
        width: 100%;
        height: 65px;
        position: absolute;
        right: -12px;
        bottom: -12px; }
      article .common_btn span {
        display: block;
        position: relative;
        color: #fff;
        background-color: #00a3a8;
        border: 1px solid #00a3a8;
        width: 100%;
        height: 100%;
        font-size: 18px;
        margin-top: 30px;
        padding: 25px 100px;
        z-index: 2;
        transition: .3s; }
        article .common_btn span:before {
          content: "";
          display: inline-block;
          border: 1px solid #fff;
          border-radius: 50%;
          position: absolute;
          background: url(../images/common/arrow_w.png) no-repeat center;
          top: calc(50% - 25px);
          right: 25px;
          width: 50px;
          height: 50px;
          transition: .3s; }
    article .more_btn {
      text-decoration: none;
      color: #000;
      display: inline-block;
      position: relative;
      font-weight: bold;
      font-family: "Lato", sans-serif;
      margin-top: 60px;
      font-size: 19px;
      padding-left: 90px; }
      article .more_btn:hover:before {
        background: url(../images/common/arrow_w.png) no-repeat center #00a3a8;
        border: 1px solid #00a3a8; }
      article .more_btn:before {
        content: "";
        display: inline-block;
        border: 1px solid #000;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        background: url(../images/common/arrow.png) no-repeat center;
        width: 70px;
        height: 70px;
        transition: .3s; }
      article .more_btn span {
        display: inline-block;
        background: url(../images/common/more_line.png) no-repeat left bottom;
        line-height: 1;
        padding: 15px 0 30px; }
    article h2.title, article .post h2 {
      color: #00a3a8;
      font-weight: bold;
      text-align: center;
      font-size: 26px;
      padding-top: 40px;
      margin-top: 100px;
      border-top: 3px solid #00a3a8; }
      article h2.title + .h2_en_title, article .post h2 + .h2_en_title {
        text-align: center;
        color: #00a3a8;
        margin-top: 0px;
        font-size: 18px; }
    article h2.title2 {
      font-weight: bold;
      position: relative;
      color: #00a3a8;
      font-size: 26px;
      margin-top: 80px;
      padding-right: 100px; }
      article h2.title2:after {
        content: "";
        display: block;
        background-color: #00a3a8;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        top: calc(50% - 1px); }
      article h2.title2 span {
        display: inline-block;
        background-color: #fff;
        position: relative;
        z-index: 2;
        padding-right: 30px; }
    article h3.title, article .post h3 {
      font-weight: bold;
      position: relative;
      color: #000;
      font-size: 22px;
      margin-top: 80px;
      padding-left: 40px; }
      article h3.title + p, article .post h3 + p {
        margin-top: 30px; }
      article h3.title:before, article .post h3:before {
        content: "";
        display: inline-block;
        border: 2px solid #00a3a8;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
        width: 26px;
        height: 26px; }
    article section + section {
      margin-top: 150px; }
  .kome {
    display: block;
    padding-left: 1em;
    text-indent: -1em;
    margin-top: 10px;
    font-size: 14px; }
    .kome:before {
      content: "※"; }
    .kome + .kome {
      margin-top: 0; }
  a.icon {
    display: inline-block;
    position: relative;
    padding-right: 20px; }
    a.icon:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: 50% 0;
      position: absolute;
      right: -5px;
      top: .1em; }
    a.icon[href$=pdf]:after {
      background-image: url(../images/common/icon_pdf.png); }
    a.icon[href$=doc]:after, a.icon[href$=docx]:after {
      background-image: url(../images/common/icon_doc.png); }
    a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
      background-image: url(../images/common/icon_xls.png); }
  .ico_blank {
    display: inline-block;
    width: 11px;
    height: 11px;
    background: url("../images/common/icon_blank.svg") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 5px;
    position: relative;
    top: -4px; }
  .imgLine2 {
    display: flex;
    justify-content: center; }
    .imgLine2 li {
      text-align: center;
      width: calc(50% - 30px); }
      .imgLine2 li:nth-of-type(odd) {
        margin-right: 60px; }
      .imgLine2 li img {
        max-width: 100%; }
    .imgLine2.small li {
      width: calc(33% - 40px); }
    .imgLine2 .cap {
      display: block;
      text-align: left;
      font-size: 12px;
      margin-top: 5px; }
  .imgLine3 {
    display: flex;
    justify-content: center; }
    .imgLine3 li {
      text-align: center;
      width: calc(33% - 40px); }
      .imgLine3 li:not(:nth-of-type(3n)) {
        margin-right: 60px; }
      .imgLine3 li img {
        max-width: 100%; }
    .imgLine3 .cap {
      display: block;
      text-align: left;
      font-size: 12px;
      margin-top: 5px; }
  .imgLine4 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .imgLine4 li {
      width: calc(25% - 36px);
      margin-right: 48px; }
      .imgLine4 li:nth-of-type(4n) {
        margin-right: 0; }
      .imgLine4 li:nth-of-type(n + 5) {
        margin-top: 48px; }
      .imgLine4 li a {
        display: block;
        transition: .4s; }
        .imgLine4 li a:hover {
          opacity: .7; }
      .imgLine4 li img {
        width: 100%; }
    .imgLine4 .cap {
      display: block;
      text-align: left;
      font-size: 12px;
      margin-top: 5px; }
  .imgInP {
    overflow: hidden; }
    .imgInP .img {
      max-width: 300px; }
      .imgInP .img img {
        width: 100%;
        height: auto; }
      .imgInP .img .cap {
        display: block;
        text-align: left;
        font-size: 13px;
        margin-top: 5px; }
    .imgInP .img.left {
      float: left;
      margin-right: 20px; }
    .imgInP .img.right {
      float: right;
      margin-left: 20px; }
  .twoCol {
    display: flex; }
    .twoCol.items_center {
      align-items: center; }
    .twoCol.wide {
      margin-top: 90px; }
      .twoCol.wide .img {
        width: 57%; }
      .twoCol.wide .txt {
        width: 43%; }
    .twoCol.narrow .img {
      width: 38%; }
    .twoCol.narrow .txt {
      width: 62%; }
    .twoCol.logo .img {
      width: 180px; }
    .twoCol.logo .txt {
      width: calc(100% - 180px); }
    .twoCol > div {
      width: 50%; }
      .twoCol > div:first-of-type {
        padding-right: 30px; }
      .twoCol > div:last-of-type {
        padding-left: 30px; }
    .twoCol .txt {
      flex: 1; }
      .twoCol .txt *:nth-child(1) {
        margin-top: 0; }
    .twoCol .img.round {
      overflow: hidden;
      border-radius: 30px; }
    .twoCol .img img {
      width: 100%;
      height: auto; }
    .twoCol .img .cap {
      display: block;
      margin-top: 5px;
      font-size: 13px;
      text-align: left; }
    .twoCol .img .modal_image {
      text-decoration: none;
      display: block;
      transition: .4s; }
      .twoCol .img .modal_image:hover {
        opacity: .7;
        color: #000; }
      .twoCol .img .modal_image span {
        display: block;
        margin-top: 5px;
        text-align: right;
        font-size: 13px; }
    .twoCol .img .modal_slide:not(:first-of-type) {
      display: none; }
    .twoCol .twoCol_title {
      font-weight: bold;
      font-size: 28px;
      position: relative;
      padding-top: 28px; }
      .twoCol .twoCol_title:before {
        content: "";
        display: inline-block;
        background: url(../images/common/h2_kazari.png) no-repeat;
        width: 26px;
        height: 26px;
        position: absolute;
        top: 0;
        left: -28px; }
      .twoCol .twoCol_title + p {
        margin-top: 20px; }
    .twoCol .twoCol_title_2 {
      font-weight: bold;
      font-size: 28px;
      position: relative;
      padding-left: 75px; }
      .twoCol .twoCol_title_2 .num {
        font-family: "Oswald", sans-serif;
        font-size: 50px;
        color: #868686;
        position: absolute;
        top: -6px;
        left: 0;
        font-weight: normal;
        line-height: 1; }
        .twoCol .twoCol_title_2 .num span {
          color: #00a3a8; }
      .twoCol .twoCol_title_2 + p {
        margin-top: 20px; }
  ul.commonList li {
    padding-left: 15px;
    position: relative;
    box-sizing: border-box; }
    ul.commonList li:not(:first-of-type) {
      margin-top: 10px; }
    ul.commonList li:before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      margin: auto;
      content: '';
      border-radius: 50%;
      background-color: #00a3a8;
      position: absolute;
      left: 0;
      top: 14px; }
  ul.col2List {
    display: flex;
    flex-wrap: wrap; }
    ul.col2List li {
      width: 50%;
      flex-basis: 50%; }
      ul.col2List li:not(:first-of-type) {
        margin-top: 0px; }
      ul.col2List li:nth-of-type(n + 3) {
        margin-top: 10px; }
  ol {
    padding-left: 18px; }
    ol li + li {
      margin-top: 10px; }
  .commonTable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: inherit;
    background-color: #FAFAFA; }
    .commonTable td.wFix,
    .commonTable th.wFix {
      width: 150px; }
    .commonTable th {
      color: #000;
      text-align: left;
      font-weight: normal;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
      background-color: #F5F6F6;
      font-size: 13px;
      padding: 15px 20px; }
      .commonTable th.gray {
        background-color: #F5F6F6; }
      .commonTable th.null {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        background-color: #fff; }
    .commonTable td {
      padding: 12px;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
      word-break: break-all;
      font-size: 13px;
      padding: 15px 20px; }
      .commonTable td.gray {
        background-color: #F5F6F6; }
      .commonTable td *:last-child {
        margin-bottom: 0; }
      .commonTable td *:first-child {
        margin-top: 0; }
      .commonTable td.null {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff; }
  .scrollTable {
    overflow: auto; }
  .scrolltext {
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 20px 0 -10px; }
  .colorBox {
    background-color: #f5f5f5;
    padding: 60px 80px; }
    .colorBox *:nth-child(1) {
      margin-top: 0; }
    .colorBox .title {
      display: block;
      font-size: 18px;
      margin-bottom: 10px; }
  .borderBox {
    border: 1px solid #00a3a8;
    padding: 20px; }
    .borderBox + .borderBox {
      margin-top: 30px; }
    .borderBox.wide {
      padding: 45px 70px; }
    .borderBox *:nth-child(1) {
      margin-top: 0; }
    .borderBox .borderBoxTitle {
      font-weight: bold;
      font-size: 18px; }
      .borderBox .borderBoxTitle + p {
        margin-top: 10px; }
  .col2_wrap {
    display: flex;
    flex-wrap: wrap; }
    .col2_wrap .left {
      width: calc(50% - 15px);
      margin-right: 30px; }
    .col2_wrap .right {
      width: calc(50% - 15px); }
  .border-box {
    border: 1px solid #ccc;
    padding: 20px; }
  .common_dl_wrap dl {
    display: flex;
    padding: 15px 0; }
    .common_dl_wrap dl:not(:first-of-type) {
      border-top: 1px solid #868686; }
    .common_dl_wrap dl dt {
      font-weight: bold;
      width: 125px;
      padding: 0px 30px 0px 0; }
    .common_dl_wrap dl dd {
      width: calc(100% - 125px);
      padding: 0px 0 0px 0px; }
  .common_dl_wrap.small {
    margin-top: 10px; }
    .common_dl_wrap.small dl {
      display: flex;
      padding: 10px 0; }
      .common_dl_wrap.small dl:not(:first-of-type) {
        border-top: none; }
      .common_dl_wrap.small dl dt {
        width: 100px;
        padding: 0px 30px 0px 0; }
      .common_dl_wrap.small dl dd {
        width: calc(100% - 100px);
        padding: 0px 0 0px 0px; }
  .common_dl_wrap.big {
    border-top: 1px solid #868686;
    border-bottom: 1px solid #868686; }
    .common_dl_wrap.big dl dt {
      font-size: 22px;
      width: 240px;
      padding-left: 40px;
      padding-top: 30px;
      padding-bottom: 30px;
      line-height: 1; }
    .common_dl_wrap.big dl dd {
      width: calc(100% - 240px);
      padding-top: 30px;
      padding-bottom: 30px; }
    .common_dl_wrap.big.type2 dl dt {
      font-size: 15px; }
  .ytWrap {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto; }
    .ytWrap .ytWrap_inner {
      position: relative;
      padding-bottom: 56.25%; }
    .ytWrap iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
  /* common class */
  strong {
    font-weight: bold !important; }
  .taC {
    text-align: center !important; }
  .taL {
    text-align: left !important; }
  .taR {
    text-align: right !important; }
  .Center {
    margin: 0 auto !important; }
  .ovh {
    overflow: hidden !important; }
  .mw100 {
    max-width: 100% !important;
    width: auto !important; }
  .w100p {
    width: 100% !important; }
  .inline {
    display: inline !important; }
  .mB0 {
    margin-bottom: 0 !important; }
  .mB5 {
    margin-bottom: 5px !important; }
  .mB10 {
    margin-bottom: 10px !important; }
  .mB20 {
    margin-bottom: 20px !important; }
  .mB30 {
    margin-bottom: 30px !important; }
  .mB40 {
    margin-bottom: 40px !important; }
  .mB50 {
    margin-bottom: 50px !important; }
  .mT0 {
    margin-top: 0 !important; }
  .mT5 {
    margin-top: 5px !important; }
  .mT10 {
    margin-top: 10px !important; }
  .mT20 {
    margin-top: 20px !important; }
  .mT30 {
    margin-top: 30px !important; }
  .mT40 {
    margin-top: 40px !important; }
  .mT50 {
    margin-top: 50px !important; }
  .mL0 {
    margin-left: 0px !important; }
  .mL5 {
    margin-left: 5px !important; }
  .mL10 {
    margin-left: 10px !important; }
  .mL20 {
    margin-left: 20px !important; }
  .mL30 {
    margin-left: 30px !important; }
  .mL40 {
    margin-left: 40px !important; }
  .mL50 {
    margin-left: 50px !important; }
  .mR0 {
    margin-right: 0px !important; }
  .mR5 {
    margin-right: 5px !important; }
  .mR10 {
    margin-right: 10px !important; }
  .mR20 {
    margin-right: 20px !important; }
  .mR30 {
    margin-right: 30px !important; }
  .mR40 {
    margin-right: 40px !important; }
  .mR50 {
    margin-right: 50px !important; }
  .mB0_pc {
    margin-bottom: 0 !important; }
  .mB5_pc {
    margin-bottom: 5px !important; }
  .mB10_pc {
    margin-bottom: 10px !important; }
  .mB20_pc {
    margin-bottom: 20px !important; }
  .mB30_pc {
    margin-bottom: 30px !important; }
  .mB40_pc {
    margin-bottom: 40px !important; }
  .mB50_pc {
    margin-bottom: 50px !important; }
  .mT0_pc {
    margin-top: 0 !important; }
  .mT5_pc {
    margin-top: 5px !important; }
  .mT10_pc {
    margin-top: 10px !important; }
  .mT20_pc {
    margin-top: 20px !important; }
  .mT30_pc {
    margin-top: 30px !important; }
  .mT40_pc {
    margin-top: 40px !important; }
  .mT50_pc {
    margin-top: 50px !important; }
  .mL0_pc {
    margin-left: 0px !important; }
  .mL5_pc {
    margin-left: 5px !important; }
  .mL10_pc {
    margin-left: 10px !important; }
  .mL20_pc {
    margin-left: 20px !important; }
  .mL30_pc {
    margin-left: 30px !important; }
  .mL40_pc {
    margin-left: 40px !important; }
  .mL50_pc {
    margin-left: 50px !important; }
  .mR0_pc {
    margin-right: 0px !important; }
  .mR5_pc {
    margin-right: 5px !important; }
  .mR10_pc {
    margin-right: 10px !important; }
  .mR20_pc {
    margin-right: 20px !important; }
  .mR30_pc {
    margin-right: 30px !important; }
  .mR40_pc {
    margin-right: 40px !important; }
  .mR50_pc {
    margin-right: 50px !important; }
  .size12 {
    font-size: 12px !important; }
  .size14 {
    font-size: 14px !important; }
  .size16 {
    font-size: 16px !important; }
  .size18 {
    font-size: 18px !important; }
  .size20 {
    font-size: 20px !important; }
  .taC_pc {
    text-align: center !important; }
  .pointerNone {
    cursor: default;
    text-decoration: none; }
    .pointerNone:hover {
      color: #000; }
  .textred {
    color: red !important; }
  .textblue {
    color: blue !important; }
  .bold {
    font-weight: bold !important; }
  .underline {
    text-decoration: underline !important; }
  .bdnone {
    border: none !important; }
  .floatL {
    float: left; }
  .floatR {
    float: right; }
  .preBox {
    background: #eff0f4;
    border: 1px solid #00a3a8;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8; }
    .preBox pre {
      overflow: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align-last: auto; } }
